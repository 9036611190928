import React from 'react';
import {useSelector} from "react-redux";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import LabelTextField from "../../../shared/LabelTextField";

const LineIDField = (() => {
    const columnName = "line_id";
    const {lang} = useSelector(({app, case_one}) => {
        return {lang: app.lang, value: case_one[columnName]};
    });

    return <LabelTextField
        columnName={columnName}
        label={get_msg_txt(lang, msg.line_id)}
    />
});

export default LineIDField;

LineIDField.propTypes = {
};
