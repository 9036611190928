import React from "react";
import {useDispatch} from "react-redux";
import {ON_NEXT_STEP} from "../../../actions/types";
import Button from "../shared/Button";

const NextStepButton = ({disabled=false, text}) => {
    const dispatch = useDispatch();

    return <Button disabled={disabled} onClick={()=>{
        dispatch({ type: ON_NEXT_STEP });
    }}>{text}</Button>
};

export default NextStepButton;
