import React from "react";
import styled, {css} from "styled-components";

const Container = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 12.5rem;
  
  display: flex;
  align-items: center;
  
  div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  ${props => props.isCross && css`
    text-decoration: line-through;
    text-decoration-color: red;
  `};
`;

const Text = (({children, isCross}) => {
    return <Container isCross={isCross}>
        <div>
            {children}
        </div>
    </Container>
});

export default Text;

Text.propTypes = {
};
