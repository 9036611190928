import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.input`
  padding-left: 5px;
  padding-right: 5px;
  border: solid .5px #AFAEAE;
  font: inherit;
  appearance: none;
  
  &:focus{
    outline: none;
  }
`;

const NumberField = ({className, value, onChange})=>{
    return <Container type="number" min={1} max={50000} className={className} value={value} onChange={onChange} />
}

export default NumberField;

NumberField.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number,
    onChange: PropTypes.func,
};
