import React from 'react';
import useIsWeb from "../../../hooks/useIsWeb";
import MobileConfirmation from "./mobile";
import WebConfirmation from "./web";

const Confirmation = () => {
    const isWeb = useIsWeb();
    if(isWeb){
        return <WebConfirmation />
    }
    else{
        return <MobileConfirmation />
    }
};

export default Confirmation;

Confirmation.propTypes = {
};
