import _ from 'lodash';
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";
import useCaseOne from "../../../../hooks/useCaseOne";
import utils from "../../../../utils/utils";
import getAnimalLocation from "./getAnimalLocation";
import useAnimalSpeciesList from "../../../../hooks/useAnimalSpeciesList";
import useComplaintCategoryList from "../../../../hooks/useComplaintCategoryList";

const useSecondRows = () => {
    const lang = useLang();
    const {
        time_of_sighting,
        animal_location_city,
        animal_location_area,
        animal_location_road,
        animal_location_details,
        animal_details=[],
        details,
    } = useCaseOne();
    const {isLoading: isAnimalSpeciesLoading, data: animalSpeciesData} = useAnimalSpeciesList();
    const {isLoading: isComplaintCategoryLoading, data: complaintCategoryData} = useComplaintCategoryList();

    if(isAnimalSpeciesLoading || isComplaintCategoryLoading){
        return [];
    }

    const animalSpeciesObj = _.mapKeys(animalSpeciesData.data, e=>e.id);
    const complaintCategoryObj = _.mapKeys(complaintCategoryData.data, e=>e.id);

    const animalDetailsRows = animal_details.map(({animal_species, count, complaint_category=[]})=>{
        return [
            {isLine: true},
            {left: get_msg_txt(lang, msg.confirm_animal_species), right: animalSpeciesObj[animal_species] && animalSpeciesObj[animal_species].name},
            {left: get_msg_txt(lang, msg.confirm_animal_count), right: count},
            {left: get_msg_txt(lang, msg.confirm_complaint_category), right: complaint_category.map(e=>complaintCategoryObj[e] && complaintCategoryObj[e].name).join("/") },
        ]
    })

    return [
        {left: get_msg_txt(lang, msg.confirm_time_of_sighting), right: utils.get_confirmation_date_format(time_of_sighting)},
        {left: get_msg_txt(lang, msg.confirm_animal_address), right: getAnimalLocation({animal_location_city, animal_location_area, animal_location_road, animal_location_details})},
        ...animalDetailsRows.flat(),
        {isLine: true},
        {left: get_msg_txt(lang, msg.confirm_details), right: details}
    ]
};

export default useSecondRows;
