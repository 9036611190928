import {useQuery} from "react-query";
import ajax from "../../../../../../../actions/ajax";

function useAreaAjax({animal_location_city}){
    const url = `/address?animal_location_city=${animal_location_city}`;

    return useQuery(['animal_location_city', animal_location_city], () => ajax.get(url));
}

export default useAreaAjax;
