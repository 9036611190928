import {useSelector} from "react-redux";
import {getError as getNameError} from "../mobile/NameField";
import {getError as getEmailError} from "../mobile/EmailField";
import {getError as getPhoneError} from "../mobile/PhoneField";
import {DEFAULT_SELECT_VALUE} from "../../../../config";
import useLang from "../../../../hooks/useLang";

const getHasError = ({nameError, emailError, phoneError, whistleblower_gender, whistleblower_age,  whistleblower_source}) => {
    return nameError || emailError || phoneError || whistleblower_gender === DEFAULT_SELECT_VALUE || whistleblower_age === DEFAULT_SELECT_VALUE || whistleblower_source === DEFAULT_SELECT_VALUE;
}

const useHasError = () => {
    const lang = useLang();
    const {whistleblower_name, whistleblower_email, whistleblower_phone, whistleblower_gender, whistleblower_age,  whistleblower_source} = useSelector(state=>{
        return state.case_one;
    })
    return getHasError({nameError: getNameError(lang, whistleblower_name), emailError: getEmailError(lang, whistleblower_email), phoneError: getPhoneError(lang, whistleblower_phone), whistleblower_gender, whistleblower_age,  whistleblower_source});
};

export default useHasError;
