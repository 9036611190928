import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import _GreenCircle from "../../../../components/GreenCircle";
import _RedCircle from "../../../../components/RedCircle";

const Container = styled.div`
  height: 78px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.25rem;
  background-color: #F2EFEF;
  user-select: none;
  cursor: pointer;
`;

const RedCircle = styled(_RedCircle)`
  margin-right: 1rem;
`

const GreenCircle = styled(_GreenCircle)`
  margin-right: 1rem;
`

const Wrapper = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;


const AccordionSummary = (({isRedCircle, title})=>{
    return <Container>
        <Wrapper>
            <Left>
                {isRedCircle ? <RedCircle /> : <GreenCircle />}
                {title}
            </Left>
        </Wrapper>
    </Container>
});

export default AccordionSummary;

AccordionSummary.propTypes = {
    isGreen: PropTypes.bool,
    title: PropTypes.string
};
