import React from "react";
import styled from "styled-components";
import AddButton from "../mobile/AddButton";
import useAnimalDetails from "../shared/useAnimalDetails";
import KeyValueField from "../../../../shared/KeyValueField";
import msg, {get_msg_txt} from "../../../../../../consts/msg";
import RedStar from "../../../../RedStar";
import AnimalSpeciesSelect from "../../AnimalSpeciesSelect";
import useLang from "../../../../../../hooks/useLang";
import WholeRow from "../../../../WholeRow";
import useOnChange from "../shared/useOnChange";
import NumberField from "../../../../shared/NumberField";
import ComplaintCategorySelect from "../../ComplaintCategorySelect";
import DelButton from "../mobile/DelButton/DelButton";
import ButtonRow from "../mobile/ButtonRow";

const Container = styled.div`
  width: 39.75rem;
  border-radius: 10px;
`;

const AnimalDetail = styled(WholeRow)`
  background-color: #F0F4FD;
  border-radius: 10px;
  padding: 1.125rem;
`

const CountField = styled(KeyValueField)`
  margin-top: 1.25rem;
`;

const ComplaintCategoryField = styled(KeyValueField)`
  margin-top: 1.25rem;
`;

const WebAnimalDetails = () => {
    const lang = useLang();
    const onChange = useOnChange();
    const animalDetails = useAnimalDetails();

    return <Container>
        <AddButton />
        {
            animalDetails.map(({id, animal_species, count, complaint_category})=> {
                return <AnimalDetail key={id}>
                    <KeyValueField left={<>{get_msg_txt(lang, msg.animal_species_label)}<RedStar/></>}
                                   right={<AnimalSpeciesSelect id={id} value={animal_species} />}/>
                    <CountField left={<>{get_msg_txt(lang, msg.animal_species_count)}<RedStar /></>} right={<NumberField value={count} onChange={({target:{value}})=>{
                        onChange({id, value: parseInt(value), columnName: "count"});
                    }} />} />
                    <ComplaintCategoryField left={<>{get_msg_txt(lang, msg.complaint_categories_label)}<RedStar /></>} right={<ComplaintCategorySelect value={complaint_category} onChange={value=>{
                        onChange({id, value, columnName: "complaint_category"});
                    }} />} />
                    <ButtonRow>
                        <DelButton id={id} />
                    </ButtonRow>
                </AnimalDetail>
            })
        }
    </Container>
};

export default WebAnimalDetails;
