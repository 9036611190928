import React from 'react';
import styled from 'styled-components';
import {RiAddCircleFill} from "react-icons/ri";
import useLang from "../../../../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../../../../consts/msg";
import {ON_ANIMAL_DETAILS_ADD} from "../../../../../../../actions/types";
import {useDispatch} from "react-redux";

const Container = styled.div`
  display: inline-flex;
  align-items: center;
  user-select: none;
  cursor: pointer;  
`;

const Icon = styled(RiAddCircleFill)`
  color: #79B256;
  font-size: 1.25rem;
`;

const Text = styled.div`
  color: #9F9797;
  font-size: 0.875rem;
  margin-left: 7px;
  
  transition: color 0.25s ease;
  
  &:hover{
    color: black;
  }
`;

const AddButton = ({className}) => {
    const dispatch = useDispatch();
    const lang = useLang();
    const text = get_msg_txt(lang, msg.add_animal_detail);

    return <Container onClick={()=>{
        dispatch({type: ON_ANIMAL_DETAILS_ADD});
    }} title={text} className={className}>
        <Icon />
        <Text>{text}</Text>
    </Container>
};

export default AddButton;

AddButton.propTypes = {

};
