import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";
import {options} from "../../YourInformation/mobile/SourceField";
import useCaseOne from "../../../../hooks/useCaseOne";
import utils from "../../../../utils/utils";

const useFirstRows = () => {
    const lang = useLang();
    const {
        whistleblower_name,
        whistleblower_email,
        whistleblower_phone,
        line_id,
        whistleblower_gender,
        whistleblower_age,
        whistleblower_source
    } = useCaseOne();

    return [
        {left: get_msg_txt(lang, msg.confirm_name), right: whistleblower_name},
        {left: get_msg_txt(lang, msg.confirm_email), right: whistleblower_email},
        {left: get_msg_txt(lang, msg.confirm_phone), right: whistleblower_phone},
        {left: get_msg_txt(lang, msg.confirm_line_id), right: line_id},
        {left: get_msg_txt(lang, msg.confirm_gender), right: utils.get_gender_label({lang, gender: whistleblower_gender})},
        {left: get_msg_txt(lang, msg.confirm_age), right: whistleblower_age},
        {left: get_msg_txt(lang, msg.confirm_source), right: get_msg_txt(lang, options.find(e=>e.value===whistleblower_source).msg)},
    ]
};

export default useFirstRows;