import React from 'react';
import styled from 'styled-components';
import KeyValueField from "../../../../shared/KeyValueField";
import AnimalSpeciesSelect from "../../AnimalSpeciesSelect";
import ComplaintCategorySelect from "../../ComplaintCategorySelect";
import WholeRow from "../../../../WholeRow";
import AddButton from "./AddButton";
import RedStar from "../../../../RedStar";
import _NumberField from "../../../../shared/NumberField";
import DelButton from "./DelButton";
import ButtonRow from "./ButtonRow";
import msg, {get_msg_txt} from "../../../../../../consts/msg";
import useLang from "../../../../../../hooks/useLang";
import useAnimalDetails from "../shared/useAnimalDetails";
import useOnChange from "../shared/useOnChange";

const Container = styled.div`
  background-color: #F0F4FD;
`;

const NumberField = styled(_NumberField)`
  width: 100%;
  height: 40px;
`
const CountField = styled(KeyValueField)`
  margin-top: 1.25rem;
`;

const ComplaintCategoryField = styled(KeyValueField)`
  margin-top: 1.25rem;
`;

const AnimalDetail = styled(WholeRow)`
  &:before{
    content: '';
    width: 100%;
    height: 1px;
    background-color: #9F9797;
    display: block;
    margin-bottom: 1.25rem;
  }
`

const MobileAnimalDetails = (() => {
    const onChange = useOnChange();
    const lang = useLang();
    const animalDetails = useAnimalDetails();

    return <Container>
        <ButtonRow>
            <AddButton />
        </ButtonRow>
        {
            animalDetails.map(({id, animal_species, count, complaint_category})=>{
                return <AnimalDetail key={id}>
                    <KeyValueField
                        left={<>{get_msg_txt(lang, msg.animal_species_label)}<RedStar /></>}
                        right={<AnimalSpeciesSelect id={id} value={animal_species} />}
                    />
                    <CountField
                        left={<>{get_msg_txt(lang, msg.animal_species_count)}<RedStar /></>}
                        right={<NumberField id={id} value={count} onChange={({target:{value}})=>{
                        onChange({id, value: parseInt(value), columnName: "count"});
                    }} />} />
                    <ComplaintCategoryField left={<>{get_msg_txt(lang, msg.complaint_categories_label)}<RedStar /></>} right={<ComplaintCategorySelect value={complaint_category} onChange={value=>{
                        onChange({id, value, columnName: "complaint_category"});
                    }} />} />
                    <ButtonRow>
                        <DelButton id={id} />
                    </ButtonRow>
                </AnimalDetail>
            })
        }
    </Container>
});

export default MobileAnimalDetails;

MobileAnimalDetails.propTypes = {

};
