import _ from "lodash";
import React from 'react';
import {ROWS_PER_PAGE} from '../../config';
import Paper from '@material-ui/core/Paper/Paper';
import Table from '@material-ui/core/Table/Table';
import TableHead from '@material-ui/core/TableHead/TableHead';
import TableRow from '@material-ui/core/TableRow/TableRow';
import TableCell from '@material-ui/core/TableCell/TableCell';
import TableBody from '@material-ui/core/TableBody/TableBody';
import TableFooter from '@material-ui/core/TableFooter/TableFooter';
import TablePagination from '@material-ui/core/TablePagination/TablePagination';
import {makeStyles} from "@material-ui/core/styles";
import TablePaginationActions from './TablePaginationActions/TablePaginationActions';
import NotFound from '../NotFound/NotFound';
import msg, {get_msg_txt} from '../../consts/msg';
import useLang from "../../hooks/useLang";
import Loading from "../Loading/Loading";
import usePage from "../../hooks/usePage";
import useAnnouncementList from "../../hooks/useAnnouncementList";
import CaseAnimalDetailRow from "./CaseAnimalDetailRow";
import utils from "../../utils/utils";
import {route_path} from "../../routes/Announcement_Route";
import useScrollToTop from "../../hooks/useScrollToTop";

function compare(a={}, b={}) {
    return a.priority - b.priority;
}

const useStyles = makeStyles({
    case_num_column: {
        width: 147,
        color: "white",
        fontWeight: "bold"
    },
    animal_species_column: {
        width: 140,
        color: "white",
        fontWeight: "bold"
    },
    complaint_categories_column: {
        width: 245,
        color: "white",
        fontWeight: "bold"
    },
    current_brief_column: {
        color: "white",
        fontWeight: "bold"
    },
    current_brief: {
        wordBreak: "break-word"
    },
    ul:{
        listStyleType: "decimal",
        paddingLeft: 20
    },
    Paper: {
        width: '100%',
        overflowX: 'auto',
    },
    row: {
        backgroundColor: "#6C87C6",
        height: 50
    }
});

function TableThCell(props={}){
    return <TableCell {...props} component="th" scope="row" />
}

const test_id = "announcement_table";
const rowsPerPage = ROWS_PER_PAGE;

const CaseTable = () => {
    useScrollToTop();
    const page = usePage();
    const {isLoading, data} = useAnnouncementList(page);
    const lang = useLang();
    const classes = useStyles();

    if(isLoading){
        return <Loading />
    }

    const {count, records=[]} = data.data;
    const isNotFound = _.isEmpty(records);

    return (
        <div test_id={test_id}>
            <Paper className={classes.Paper}>
                <Table>
                    <TableHead>
                        <TableRow className={classes.row}>
                            <TableCell className={classes.case_num_column}>
                                {get_msg_txt(lang, msg.case_num_label)}
                            </TableCell>
                            <TableCell className={classes.animal_species_column}>
                                {get_msg_txt(lang, msg.animal_species_label)}
                            </TableCell>
                            <TableCell className={classes.complaint_categories_column}>
                                {get_msg_txt(lang, msg.complaint_categories_label)}
                            </TableCell>
                            <TableCell className={classes.current_brief_column}>
                                {get_msg_txt(lang, msg.current_brief_label)}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {isNotFound &&
                        <TableRow>
                            <TableCell component="th" scope="row" colSpan={8}>
                                <NotFound lang={lang} />
                            </TableCell>
                        </TableRow>
                        }
                        {_.values(records).sort(compare).map(({case_num, current_brief, animal_details=[]}, index) => {
                            return (
                                <TableRow key={`${case_num}_${index}`} hover>
                                    <TableThCell>{case_num}</TableThCell>
                                    <TableThCell colSpan={2}>
                                        <CaseAnimalDetailRow animal_details={animal_details} />
                                    </TableThCell>
                                    <TableThCell>{current_brief}</TableThCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                    <TableFooter>
                        <TableRow>
                            <TablePagination
                                colSpan={8}
                                count={count}
                                rowsPerPageOptions={[ROWS_PER_PAGE]}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onChangePage={(e, page) => {
                                    const filters = utils.get_filters();
                                    utils.changeParams({route_path, filters, property: "page", value: page, type: "Number"});
                                }}
                                ActionsComponent={TablePaginationActions}
                                labelDisplayedRows={({ from, to, count }) => get_msg_txt(lang, msg.labelDisplayedRows_label, { from, to, count })}
                            />
                        </TableRow>
                    </TableFooter>
                </Table>
            </Paper>
        </div>
    );
}

export default CaseTable;

CaseTable.propTypes = {

};
