import React from "react";
import WholeRow from "../../../../WholeRow";
import HintText from "../shared/HintText";
import Title from "../shared/Title";
import TextArea from "../shared/TextArea";

const MobileDetailsRow = () => {
    return <>
        <WholeRow>
            <Title />
        </WholeRow>
        <WholeRow>
            <HintText />
        </WholeRow>
        <WholeRow>
            <TextArea />
        </WholeRow>
    </>;
};

export default MobileDetailsRow;
