import PropTypes from "prop-types";
import styled from "styled-components";

const LinkBase = styled.a`
  color: #2355DC;
  text-decoration: none;
  
  &:hover{
    text-decoration: underline;
  }
`

LinkBase.propTypes = {
    number: PropTypes.string,
    test_id: PropTypes.string
};

export default LinkBase;
