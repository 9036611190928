import React from 'react';
import {Icon} from "@material-ui/core";

const VideoButton = (()=>{
    return <Icon
        className='fas fa-video'
        style={{ fontSize: 50 }}
    />
});

export default VideoButton;

VideoButton.propTypes = {}
