import _ from "lodash";
import {receiveFetching} from "./fetching";
import {receiveErrorMsg} from "./error_msg";
import util from "../utils/utils";
import ajax from "./ajax";

export function execute({method, url, name, type, data, callback, errorCallback}) {
    return (dispatch) => {
        dispatch(receiveFetching({[name]: true}));
        dispatch(receiveErrorMsg({[name]: {}}));
        return ajax[method](url, data).then(({data}) => {
            if (_.isFunction(callback)) {
                callback({dispatch, data});
            }
            else{
                dispatch({
                    type,
                    payload: data
                });
                dispatch(receiveFetching({[name]: false}));
            }
        }).catch((err) => {
            const error = util.get_error_obj(err);
            if (_.isFunction(errorCallback)) {
                errorCallback(error);
            }
            else{
                dispatch(receiveErrorMsg({[name]: error}));
            }
            dispatch(receiveFetching({[name]: false}));
        });
    };
}

export function get({url, name, type, params, callback, errorCallback}) {
    const method = "get";
    const data = {params};
    return execute({method, url, name, type, data, callback, errorCallback});
}

export function post({url, name, type, body={}, callback, errorCallback}) {
    const method = "post";
    const data = body;
    return execute({method, url, name, type, data, callback, errorCallback});
}