import React from 'react';
import styled from "styled-components";
import AccordionSummary from "../../../../layouts/StepLayout/mobile/AccordionSummary";
import AccordionContent from "../../../../layouts/StepLayout/mobile/AccordionContent";
import WholeRow from "../../WholeRow";
import RedStar from "../../RedStar";
import UploadButton from "../shared/UploadButton";
import CaseFiles from "../shared/CaseFiles";
import msg, {get_msg_txt} from "../../../../consts/msg";
import IsReportedField from "../shared/IsReportedField";
import IsPersonalInfo from "../shared/IsPersonalInfo";
import {PHOTO_AND_VIDEOS} from "../../../../consts/step";
import CloudLinkField from "../shared/CloudLinkField";
import NextStepButton from "../../NextStepButton";
import ReportAgencyField from "../shared/ReportAgencyField";
import PrevStepButton from "../../PrevStepButton";
import useLang from "../../../../hooks/useLang";
import useStep from "../../../../hooks/useStep";
import useIsNextDisabled from "../shared/useIsNextDisabled";
import useIsReported from "../shared/useIsReported";
import useScrollToTop from "../../../../hooks/useScrollToTop";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const TitleRow = styled(WholeRow)`
  font-size: 1.125rem;
`;

const SmallRedFontRow = styled(WholeRow)`
  font-size: 0.6875rem;
  color: #CB1212;
`

const UploadRow = styled(WholeRow)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const RadioRow = styled(WholeRow)`
  display: flex;
  justify-content: center;
`;

const SubmitRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const MobilePhotosAndVideos = (() => {
    useScrollToTop();
    const lang = useLang();
    const step = useStep();
    const open = step === PHOTO_AND_VIDEOS;
    const isNextDisabled = useIsNextDisabled();
    const is_reported = useIsReported();

    return <>
        <AccordionSummary isRedCircle={isNextDisabled} title={get_msg_txt(lang, msg.photos_and_videos)} open={open} />
        <AccordionContent open={open}>
            {open && <Container>
                <TitleRow>{get_msg_txt(lang, msg.photos_and_videos_title)}<RedStar /></TitleRow>
                <SmallRedFontRow>{get_msg_txt(lang, msg.upload_hint1)}</SmallRedFontRow>
                <SmallRedFontRow>{get_msg_txt(lang, msg.upload_hint2)}</SmallRedFontRow>
                <UploadRow>
                    <UploadButton />
                </UploadRow>
                <UploadRow>
                    <CaseFiles />
                </UploadRow>
                <WholeRow>
                    {get_msg_txt(lang, msg.upload_cloud_link_hint)}
                </WholeRow>
                <WholeRow>
                    <CloudLinkField />
                </WholeRow>
                <WholeRow>
                    {get_msg_txt(lang, msg.is_report_to_other_ngo)}
                </WholeRow>
                <RadioRow>
                    <IsReportedField />
                </RadioRow>
                {is_reported && <RadioRow><ReportAgencyField /></RadioRow>}
                <WholeRow>
                    {get_msg_txt(lang, msg.is_personal_info)}
                </WholeRow>
                <RadioRow>
                    <IsPersonalInfo />
                </RadioRow>
                <SubmitRow>
                    <PrevStepButton />
                    <NextStepButton text={get_msg_txt(lang, msg.submit_btn)} disabled={isNextDisabled} />
                </SubmitRow>
            </Container>}
        </AccordionContent>
    </>
});

export default MobilePhotosAndVideos;

MobilePhotosAndVideos.propTypes = {
};
