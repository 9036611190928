import update from "immutability-helper";
import v4 from "uuid";
import {
    ON_ADDRESS_AREA_CHANGE,
    ON_ADDRESS_CITY_CHANGE,
    ON_ADDRESS_DETAILS_CHANGE,
    ON_ADDRESS_ROAD_CHANGE,
    ON_ANIMAL_DETAILS_ADD,
    ON_ANIMAL_DETAILS_CHANGE,
    ON_ANIMAL_DETAILS_REMOVE,
    ON_CASE_FIELD_CHANGE,
    ON_CASE_FILE_DELETED,
    ON_CASE_FILE_FAILED,
    ON_CASE_FILE_TOO_BIG,
    ON_CASE_FILE_UPLOAD_PROGRESS,
    ON_CASE_FILE_UPLOADED,
    ON_CASE_FILES_UPLOADING,
} from '../actions/types';
import {DEFAULT_CITY_VALUE} from "../components/Home/AnimalInformation/shared/PlaceRow/shared/CitySelect";
import {DEFAULT_AREA_VALUE} from "../components/Home/AnimalInformation/shared/PlaceRow/shared/AreaSelect";
import {DEFAULT_ROAD_VALUE} from "../components/Home/AnimalInformation/shared/PlaceRow/shared/RoadSelect";
import utils from "../utils/utils";
import {FAILED, UPLOADED, UPLOADING} from '../consts/upload_status';
import {DEFAULT_SELECT_VALUE} from "../config";

export const NEW_ANIMAL_DETAIL = {
    id: v4(),
    animal_species: DEFAULT_SELECT_VALUE,
    count: 1,
    complaint_category: []
}

const INITIAL_STATE = {
    whistleblower_name: "",
    whistleblower_email: "",
    whistleblower_phone: "",
    whistleblower_gender: DEFAULT_SELECT_VALUE,
    whistleblower_age: DEFAULT_SELECT_VALUE,
    whistleblower_source: DEFAULT_SELECT_VALUE,
    line_id: "",
    animal_location_city: DEFAULT_CITY_VALUE,
    animal_location_area: DEFAULT_AREA_VALUE,
    animal_location_road: DEFAULT_ROAD_VALUE,
    animal_location_details: "",
    animal_details: [NEW_ANIMAL_DETAIL],
    concerns: "",
    time_of_sighting: utils.get_now_taipei_date(),
    details: "",
    case_files: [],
    cloud_link: "",
    is_reported: true,
    report_agency: "",
    is_personal_info: true
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case ON_CASE_FIELD_CHANGE: {
            const {columnName} = action;

            switch (columnName) {
                case "animal_location_city":{
                    return update(state, {
                        $merge: {
                            [columnName]: action.payload,
                            animal_location_area: INITIAL_STATE.animal_location_area,
                            animal_location_road: INITIAL_STATE.animal_location_road,
                            animal_location_details: INITIAL_STATE.animal_location_details
                        }
                    });
                }
                case "animal_location_area": {
                    return update(state, {
                        $merge: {
                            [columnName]: action.payload,
                            animal_location_road: INITIAL_STATE.animal_location_road,
                            animal_location_details: INITIAL_STATE.animal_location_details
                        }
                    });
                }
                default: {
                    const $merge = {
                        [columnName]: action.payload
                    }
                    if(columnName==="is_reported" && action.payload===false){
                        $merge.report_agency = INITIAL_STATE.report_agency;
                    }
                    return update(state, {$merge});
                }
            }
        }
        case ON_CASE_FILES_UPLOADING: {
            return update(state, {
                case_files: {
                    $push: action.payload.map(({id, file})=>{
                        return {id,  file, status: UPLOADING, percentCompleted: 0}
                    })
                }
            });
        }
        case ON_CASE_FILE_UPLOAD_PROGRESS:{
            const {id, percentCompleted} = action.payload;

            const index = state.case_files.findIndex(e=>e.id===id);
            return update(state, {
                case_files: {
                    [index]: {
                        percentCompleted: {
                            $set: percentCompleted
                        }
                    }
                }
            });
        }
        case ON_CASE_FILE_UPLOADED:{
            const {id, src} = action.payload;
            const index = state.case_files.findIndex(e=>e.id===id);

            return update(state, {
                case_files: {
                    [index]: {
                        $merge: {
                            status: UPLOADED,
                            src
                        }
                    }
                }
            });
        }
        case ON_CASE_FILE_DELETED: {
            const {id} = action.payload;
            const index = state.case_files.findIndex(e=>e.id===id);

            return update(state, {
                case_files: {
                    $splice: [[index, 1]]
                }
            });
        }
        case ON_CASE_FILE_TOO_BIG:
        case ON_CASE_FILE_FAILED: {
            const {id} = action.payload;
            const index = state.case_files.findIndex(e=>e.id===id);
            const msgKey = action.type === ON_CASE_FILE_TOO_BIG ? "upload_fail_size_too_big" : "upload_fail";

            return update(state, {
                case_files: {
                    [index]: {
                        $merge: {
                            status: FAILED,
                            msgKey
                        }
                    }
                }
            });
        }
        case ON_ADDRESS_CITY_CHANGE: {
            const animal_location_city = action.payload;
            return {...state, animal_location_city, animal_location_area: DEFAULT_AREA_VALUE, animal_location_road: DEFAULT_ROAD_VALUE, animal_location_details:""};
        }
        case ON_ADDRESS_AREA_CHANGE:{
            const animal_location_area = action.payload;
            return {...state, animal_location_area, animal_location_road: DEFAULT_ROAD_VALUE, animal_location_details:""};
        }
        case ON_ADDRESS_ROAD_CHANGE:{
            const animal_location_road = action.payload;
            return {...state, animal_location_road, animal_location_details: ""};
        }
        case ON_ADDRESS_DETAILS_CHANGE:{
            const animal_location_details = action.payload;
            return {...state, animal_location_details};
        }
        case ON_ANIMAL_DETAILS_ADD:{
            return update(state, {
                animal_details:{
                    $push : [
                        {
                            ...NEW_ANIMAL_DETAIL,
                            id: v4(),
                        }
                    ]
                }
            });
        }
        case ON_ANIMAL_DETAILS_REMOVE: {
            const {id} = action.payload;
            const index = state.animal_details.findIndex(e=>e.id===id);
            return update(state, {
                animal_details: {
                    $splice: [[index, 1]]
                }
            });
        }

        case ON_ANIMAL_DETAILS_CHANGE:{
            const {id, columnName, value} = action.payload;
            const index = state.animal_details.findIndex(e => e.id === id);

            return update(state, {
                animal_details: {
                    [index]: {
                        [columnName]: {
                            $set: value
                        }
                    }
                }
            });
        }
        default:
            return state;
    }
}
