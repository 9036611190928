import {useQuery} from "react-query";
import utils from "../utils/utils";
import ajax from "../actions/ajax";
import {DEFAULT_PAGE} from "../config";

const prefix_url = "/announcement";

const getFetchPromise = () =>{
    const url = prefix_url;

    const filters = utils.get_filters();
    for(const [key, obj] of Object.entries(filters)){
        if(obj.type ==="Array"){
            filters[key].value = window.encodeURIComponent(JSON.stringify(obj.value));
        }
    }
    return ajax.get(url, {params: {filters}});
}

const useAnnouncementList = (page = DEFAULT_PAGE) => {
    const filters = utils.get_filters();
    const {isLoading, data} = useQuery(['announcement', page, filters], () => getFetchPromise());
    return {isLoading, data};
};

export default useAnnouncementList;