export const NOTICE_FIRST = -2;
export const NOTICE_SECOND = -1;
export const YOUR_INFORMATION = 0;
export const INFORMATION_OF_ANIMAL = 1;
export const PHOTO_AND_VIDEOS = 2;
export const CONFIRMATION = 3;

export const DEFAULT_STEP = NOTICE_FIRST;

export function prev_step(step=NOTICE_SECOND){
    if(step===NOTICE_FIRST){
        return NOTICE_FIRST;
    }
    return --step;
}

export function next_step(step=NOTICE_SECOND){
    if(step===CONFIRMATION){
        return CONFIRMATION;
    }
    return ++step;
}
