import _ from 'lodash';
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import LabelTextField from "../../../shared/LabelTextField";
import utils from "../../../../../utils/utils";

export function getError(lang, value=""){
    if(_.isEmpty(value)){
        return get_msg_txt(lang, msg.phone_error);
    }
    else if(!utils.is_phone_format(value)){
        return get_msg_txt(lang, msg.phone_format_error);
    }
    return "";
}

const PhoneField = (() => {
    const [dirty, onDirty] = useState(false);
    const columnName = "whistleblower_phone";
    const {lang, value} = useSelector(({app, case_one}) => {
        return {lang: app.lang, value: case_one[columnName]};
    });

    return <LabelTextField
        columnName={columnName}
        isRequired={true}
        placeholder={get_msg_txt(lang, msg.phone_placeholder)}
        label={get_msg_txt(lang, msg.phone)}
        error={!dirty? "" :getError(lang, value)}
        onDirty={()=>onDirty(true)}
    />
});

export default PhoneField;

PhoneField.propTypes = {
};
