import React from 'react';
import msg, {get_msg_txt} from "../../../../consts/msg";
import useLang from "../../../../hooks/useLang";
import DataBlock from "../shared/DataBlock";
import useFirstRows from "../shared/useFirstRows";
import useSecondRows from "../shared/useSecondRows";
import useThirdRows from "../shared/useThirdRows";
import SubmitButtonBlock from "../shared/SubmitButtonBlock";

const MobileConfirmation = () => {
    const lang = useLang();
    const firstRows = useFirstRows();
    const secondRows = useSecondRows();
    const thirdRows = useThirdRows();

    return <>
        <DataBlock
            title={get_msg_txt(lang, msg.your_information)}
            rows={firstRows}
        />
        <DataBlock
            isWhite
            title={get_msg_txt(lang, msg.information_and_animal)}
            rows={secondRows}
        />
        <DataBlock
            title={get_msg_txt(lang, msg.photos_and_videos)}
            rows={thirdRows}
        />
        <SubmitButtonBlock />
    </>
};

export default MobileConfirmation;

MobileConfirmation.propTypes = {
};
