import React from 'react';
import styled, {css} from "styled-components";
import RedStar from "../../RedStar";
import Error from "../Error";
import useIsWeb from "../../../../hooks/useIsWeb";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 1.25rem;
`;

const Wrapper = styled.div`
  width: 16.875rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: 
    "text component"
    ".    error";
    
    ${props => props.isWeb && css`
      width: 100%;
      grid-template-columns: 7.5rem 1fr;      
    `};
`;

const Text = styled.div`
  grid-area: text;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  
  font-size: 1.125rem;
  color: #434242;
`;

const Component = styled.div`
  grid-area: component;
  display: flex;
  align-items: center;
`;

const ColumnRow = (({className, isRequired=false, label, children, error}) => {
    const isWeb = useIsWeb();

    return <Container className={className}>
        <Wrapper isWeb={isWeb}>
            <Text>
                <div>
                    {label}
                    {isRequired && <RedStar />}
                </div>
            </Text>
            <Component>
                {children}
            </Component>
            <Error error={error} />
        </Wrapper>
    </Container>
});

export default ColumnRow;

ColumnRow.propTypes = {
};
