import FileType from "file-type";
import {useEffect, useState} from "react";

export const getFileExt = async file => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onabort = () => reject('file reading was aborted');
        reader.onerror = () => reject('file reading has failed');
        reader.onload = evt => {
            if (evt.target.readyState === FileReader.DONE) {
                const buffer = evt.target.result;
                FileType.fromBuffer(buffer).then((obj) => {
                    if(obj===undefined || !obj.ext){
                        reject();
                    }
                    else{
                        resolve(obj.ext);
                    }
                });
            }
        }
        reader.readAsArrayBuffer(file);
    });
}

const useGetFileExt = (file) => {
    const [ext, setExt] = useState("");
    const [isLoading, setLoading] = useState(true);

    useEffect(() => {
        setLoading(true);
        getFileExt(file).then(setExt).finally(()=>{
            setLoading(false);
        });
    }, [file]);

    return {isLoading, ext};
};

export default useGetFileExt;