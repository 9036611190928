import React from "react";
import styled from "styled-components";
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";
import useFirstRows from "../shared/useFirstRows";
import DataBlock from "../shared/DataBlock";
import useSecondRows from "../shared/useSecondRows";
import useThirdRows from "../shared/useThirdRows";
import SubmitButtonBlock from "../shared/SubmitButtonBlock";

const Container = styled.div`
  padding: 1rem;
  width: 100%;
`;

const WebConfirmation = () => {
    const lang = useLang();
    const firstRows = useFirstRows();
    const secondRows = useSecondRows();
    const thirdRows = useThirdRows();

    return <Container>
        <DataBlock
            isWhite
            title={get_msg_txt(lang, msg.your_information)}
            rows={firstRows}
        />
        <DataBlock
            isWhite
            title={get_msg_txt(lang, msg.information_and_animal)}
            rows={secondRows}
        />
        <DataBlock
            isWhite
            title={get_msg_txt(lang, msg.photos_and_videos)}
            rows={thirdRows}
        />
        <SubmitButtonBlock />
    </Container>
};

export default WebConfirmation;