import React from 'react';
import styled from "styled-components";
import msg, {get_msg_txt} from "../../consts/msg";
import {TEL} from "../../config";
import icon from "./icon.svg";
import useLang from "../../hooks/useLang";

const IconLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TelIcon = (({className}) => {
    const lang = useLang();

    return <IconLink className={className} href={`tel:${TEL}`}>
        <img alt={get_msg_txt(lang, msg.phone)} src={icon} width={20} height={20} />
    </IconLink>
});

export default TelIcon;
