import styled from 'styled-components';
import src from './delete_icon.svg';

const DeleteButton = styled.div`
  width: 20px;
  height: 24px;
  background-repeat: no-repeat;
  background-image: url(${src});
  
  &:hover {
    cursor: pointer;
  }
`;

export default DeleteButton;

DeleteButton.propTypes = {
};
