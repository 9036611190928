import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  height: fit-content;
  width: 100%;
`;

const SelectElement = styled.select`
  width: 100%;
  height: 40px;
  padding-left: 5px;
  padding-right: 25px;
  border: solid .5px #AFAEAE;
  background-color: #FFFFFF;
  font-size: 1rem;
  appearance: none;
  text-overflow: ellipsis;
  
  &:focus{
    outline: none;
  }
`;

const Triangle = styled.div`
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid rgba(0, 0, 0, 0.54);
  
  position: absolute;
  top: calc(50% - 2.5px);
  right: 5px;
`;

const NativeSelect = ({className, multiple=false, id, value, onChange, options}) => {
    return <Container className={className}>
        <SelectElement value={value} id={id} multiple={multiple} onChange={event=>{
            if(multiple){
                onChange(Array.from(event.target.selectedOptions, option => option.value));
            }
            else{
                onChange(event.target.value);
            }
        }} >
            {options.map(({value, label})=><option key={value} value={value}>{label}</option>)}
        </SelectElement>
        <Triangle />
    </Container>
};

export default NativeSelect;
