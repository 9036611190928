import React from "react";
import useIsWeb from "../../../../../hooks/useIsWeb";
import MobileDetailsRow from "./mobile";
import WebDetailsRow from "./web";

const DetailsRow = () => {
    const isWeb = useIsWeb();
    if(isWeb){
        return <WebDetailsRow />
    }
    else{
        return <MobileDetailsRow />
    }
};

export default DetailsRow;
