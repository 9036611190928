import React from "react";
import {useSelector} from "react-redux";
import {
    CONFIRMATION,
    DEFAULT_STEP,
    INFORMATION_OF_ANIMAL,
    NOTICE_FIRST,
    NOTICE_SECOND, PHOTO_AND_VIDEOS,
    YOUR_INFORMATION
} from "../consts/step";
import {StepLayout} from "../layouts";
import YourInformation from "../components/Home/YourInformation";
import AnimalInformation from "../components/Home/AnimalInformation";
import PhotosAndVideos from "../components/Home/PhotosAndVideos";
import Confirmation from "../components/Home/Confirmation";
import FirstNotice from "../components/Home/FirstNotice";
import NoticeLayout from "../layouts/NoticeLayout";
import SecondNotice from "../components/Home/SecondNotice";
import useIsWeb from "../hooks/useIsWeb";

export const route_path = "/";

const Home_Route = (()=>{
    const isWeb = useIsWeb();
    const {step=DEFAULT_STEP} = useSelector(state=>state.app);

    switch(step){
        case NOTICE_FIRST:
        case NOTICE_SECOND: {
            return <NoticeLayout>
                {step === NOTICE_FIRST ? <FirstNotice /> : <SecondNotice />}
            </NoticeLayout>
        }
        case CONFIRMATION: {
            return <StepLayout>
                <Confirmation />
            </StepLayout>
        }
        default: {
            if(isWeb){
                let stepChildren = null;
                switch(step) {
                    case YOUR_INFORMATION:
                        stepChildren = <YourInformation />
                        break;
                    case INFORMATION_OF_ANIMAL:
                        stepChildren = <AnimalInformation />
                        break;
                    case PHOTO_AND_VIDEOS:
                        stepChildren = <PhotosAndVideos />
                        break;
                    default:
                        break;
                }
                return <StepLayout>
                    {stepChildren}
                </StepLayout>
            }
            else{
                return <StepLayout>
                    <YourInformation />
                    <AnimalInformation />
                    <PhotosAndVideos />
                </StepLayout>
            }
        }
    }
});

export default Home_Route;

Home_Route.propTypes = {
};
