import msg, {get_msg_txt} from "../../../consts/msg";
import useParameters from "./useParameters";
import useLang from "../../../hooks/useLang";
import useIsWeb from "../../../hooks/useIsWeb";

const useContent = () => {
    const lang = useLang();
    const isWeb = useIsWeb();
    const {case_num, animal_location} = useParameters();

    return get_msg_txt(lang, msg.thankyou_content_label, {case_num, animal_location, isWeb});
};

export default useContent;