import React, {useState} from 'react';
import styled, {css} from 'styled-components';
import Logo from "../../Logo";
import useLang from "../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../consts/msg";
import utils from "../../../utils/utils";
import {route_path} from "../../../routes/Announcement_Route";
import useIsWeb from "../../../hooks/useIsWeb";

const Container = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: ${ props => props.isMobile ? "column" : "row"};
`;

const Title = styled.h1`
  margin-left: 1rem;
  font-size: 1.5625rem;
`;

const Left = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: grid;
  grid-template-columns: 87px 248px auto;
  align-items: center;
  width: 415px;
  
  ${ props => props.isMobile && css`
      width: 315px;
      grid-template-columns: 87px 148px auto;
      margin-bottom: 1rem;
  `};
`;

const Text = styled.div`
  font-size: 18px;
  width: 4.5rem;
  letter-spacing: 0;
`;

const TextField = styled.input`
  height: 40px;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  font: inherit;
  border-radius: 8px;
  appearance: none;
  
  &:focus{
    outline: none;
    border: solid 2px #1976D2;
  }
`;

const Button = styled.button`
  margin-left: 15px;
  width: 65px;
  height: 38px;
  background-color: #6C87C6;
  border: none;
  color: white;
  border-radius: 6px;
  cursor: pointer;
`;

const Header = (() => {
    const isMobile = !useIsWeb();
    const filters = utils.get_filters();
    const defaultCaseNum = utils.get_value_by_cur_filters({filters, field: "case_num", default_value: ""});
    const [caseNum, setCaseNum] = useState(defaultCaseNum);
    const lang = useLang();

    const submit = (() => {
        utils.changeParams({route_path, filters, property: "case_num", value: caseNum, type: "String"});
    });

    return <Container isMobile={isMobile}>
        <Left>
            <Logo />
            <Title>
                {get_msg_txt(lang, msg.investigation_log)}
            </Title>
        </Left>
        <Right isMobile={isMobile}>
            <Text>{get_msg_txt(lang, msg.case_num_label)}</Text>
            <TextField value={caseNum} onChange={e=>{
                setCaseNum(e.target.value);
            }} onKeyDown={e=>{
                if(e.key==="Enter"){
                    submit();
                }
            }} onBlur={()=>{
                submit();
            }} />
            <Button onClick={()=>{
                submit();
            }}>{get_msg_txt(lang, msg.search)}</Button>
        </Right>
    </Container>
});

export default Header;

Header.propTypes = {
};
