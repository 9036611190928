import React from 'react';
import {useSelector} from "react-redux";
import TextRow from "../TextRow";

const LabelTextField = (({columnName, isRequired=false, placeholder, label, error, onDirty=()=>{}}) => {
    const {value} = useSelector(({app, case_one}) => {
        return {lang: app.lang, value: case_one[columnName]};
    });

    return <TextRow
        id={columnName}
        columnName={columnName}
        placeholder={placeholder}
        isRequired={isRequired}
        label={label}
        value={value}
        error={error}
        onDirty={onDirty}
    />
});

export default LabelTextField;

LabelTextField.propTypes = {
};
