import React from "react";
import styled from 'styled-components';
import KeyValueField from "../../../../shared/KeyValueField";
import HintText from "../shared/HintText";
import Title from "../shared/Title";
import SharedTextArea from "../shared/TextArea";

const TextArea = styled(SharedTextArea)`
  margin-top: 1rem;   
`;

const WebDetailsRow = () => {
    return <div>
        <KeyValueField
            left={<Title />}
            right={<HintText />}
        />
        <KeyValueField
            right={<TextArea />}
        />
    </div>;
};

export default WebDetailsRow;
