import React from "react";
import useIsWeb from "../../../hooks/useIsWeb";
import MobileFirstNotice from "./mobile";
import WebFirstNotice from "./web";

const FirstNotice = (()=>{
    const isWeb = useIsWeb();

    if(isWeb){
        return <WebFirstNotice />;
    }
    else{
        return <MobileFirstNotice/>;
    }
});

export default FirstNotice;

FirstNotice.propTypes = {
};
