import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr;
`;

const HyperlinkElement = styled.a`
  overflow:hidden;
`;

const ImageElement = styled.img`
  width: 3.125rem;
  height: 3.125rem;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.75rem;
  padding-left: 0.75rem;
  
  div{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const ImageRow = ({alt, url, name}) => {
    return <Container>
        <HyperlinkElement rel="noopener noreferrer" target="_blank" href={url}>
            <ImageElement alt={alt} src={url} />
        </HyperlinkElement>
        <Text>
            <div>
                {name}
            </div>
        </Text>
    </Container>
};

export default ImageRow;

ImageRow.propTypes = {
};
