import {useDispatch} from "react-redux";
import {ON_ANIMAL_DETAILS_CHANGE} from "../../../../../../actions/types";

const useOnChange = () => {
    const dispatch = useDispatch();

    return ({ id, columnName, value})=>{
        dispatch({
            type: ON_ANIMAL_DETAILS_CHANGE,
            payload: { id, columnName, value }
        });
    }
};

export default useOnChange;