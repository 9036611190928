import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => ({
    progress: {
        margin: theme.spacing(2),
    },
});

function Loading({ classes} ) {
    return <CircularProgress className={classes.progress} />
}

Loading.propTypes = {
    classes: PropTypes.object
};

export default withStyles(styles)(Loading);