import React from "react";
import SharedNextStepButton from "../../../NextStepButton";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import useLang from "../../../../../hooks/useLang";
import useHasError from "../../shared/useHasError";

const NextStepButton = () => {
    const hasError = useHasError();
    const lang = useLang();

    return <SharedNextStepButton
        disabled={hasError}
        text={get_msg_txt(lang, msg.next_step)}
    />
};

export default NextStepButton;
