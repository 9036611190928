import React from "react";
import appendQuery from "append-query";
import utils from '../utils/utils';
import Announcement from '../components/Announcement';

export const route_path = "/announcement";

export function redirect_route(params = {}) {
    const url = appendQuery(route_path, params);
    utils.redirect_route(url);
}

const Announcement_Route = (() => {
    return <Announcement />
});

Announcement_Route.propTypes = {
};

export default Announcement_Route;