import React from 'react';
import useIsWeb from "../../../../hooks/useIsWeb";
import MultipleSelect from "../../../MultipleSelect";
import NativeSelect from "./NativeSelect";

const Select = (({className, multiple=false, id, value, onChange, options}) => {
    const isWeb = useIsWeb();
    if(isWeb && multiple){
        return <MultipleSelect id={id} options={options} values={value} onChange={onChange} />
    }
    return <NativeSelect className={className} multiple={multiple} id={id} value={value} onChange={onChange} options={options}  />
});

export default Select;

Select.propTypes = {

};
