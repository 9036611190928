import styled from "styled-components";
import React from "react";

const Container = styled.div`
  color: #CB1212;
  font-size: 11px;
`;

const RedText = ({className, children}) => {
    return <Container className={className}>
        {children}
    </Container>
};

export default RedText;