import React from "react";
import styled, {css} from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import _LinearProgress from "@material-ui/core/LinearProgress";
import DeleteButton from "../DeleteButton";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import {ON_CASE_FILE_DELETED} from "../../../../../actions/types";
import useLang from "../../../../../hooks/useLang";
import {FAILED, UPLOADED, UPLOADING} from "../../../../../consts/upload_status";
import WholeRow from "../../../WholeRow";
import Text from "./Text";
import FailedMessage from "./FailedMessage";
import useIsWeb from "../../../../../hooks/useIsWeb";
import FilePreview from "../FilePreview";

const Container = styled.div`
  
  ${props => props.isWeb ? css`
    display: grid;
    grid-template-columns: 1fr 1fr;
  `: css`
      display: flex;
      flex-direction: column;
      justify-content: left;
      align-items: center;
    `};
`;

const CaseFile = styled.div`
  display: grid;
  grid-template-columns: 3.125rem ${props => props.isWeb? "10rem": "12.5rem"} 1.25rem;
`;

const MiddleContainer = styled.div`
  font-size: 0.75rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  
  display: grid;
  grid-template-rows: 1fr 1fr;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  
  .MuiLinearProgress-colorPrimary{
    background-color: #E3E3E3;
  }
  
  .MuiLinearProgress-bar{
    background-color: #79B256;
  }
`;

const LinearProgress = styled(_LinearProgress)`
  width: 100%;
`;

const CaseFiles = (() => {
    const isWeb = useIsWeb();
    const dispatch = useDispatch();
    const lang = useLang();
    const caseFiles = useSelector(state => state.case_one.case_files);

    const onDelete = (id) => {
        if(window.confirm(get_msg_txt(lang, msg.upload_del_msg))){
            dispatch({
                type: ON_CASE_FILE_DELETED,
                payload: {id}
            });
        }
    };

    return <Container isWeb={isWeb}>
        {caseFiles.map(({id, src, file, status, percentCompleted, msgKey})=>{
            const isUploaded = status === UPLOADED;
            const isFailed = status === FAILED;
            const isShowLoadingBar = status === UPLOADING || isUploaded;
            const isShowDeleteBtn = status !== UPLOADING;
            const failMessage = get_msg_txt(lang, msg[msgKey]);

            return <WholeRow key={id}>
                <CaseFile isWeb={isWeb}>
                    <FilePreview isUploaded={isUploaded} src={src} file={file} />
                    <MiddleContainer>
                        <Text isCross={isFailed}>
                            {file.name}
                        </Text>
                        {isShowLoadingBar && <StatusContainer>
                            <LinearProgress variant="determinate" value={percentCompleted} />
                        </StatusContainer>}
                        {isFailed && <FailedMessage>
                            {failMessage}
                        </FailedMessage>}
                    </MiddleContainer>
                    {isShowDeleteBtn && <FlexBox onClick={()=>onDelete(id)}>
                        <DeleteButton />
                    </FlexBox>}
                </CaseFile>
            </WholeRow>
        })}
    </Container>
});

export default CaseFiles;

CaseFiles.propTypes = {
};
