import React from "react";
import green_circle from "./green_circle.svg";
import Circle from "../Circle";

const GreenCircle = (({className})=>{
    return <Circle className={className} src={green_circle}/>;
});

export default GreenCircle;

GreenCircle.propTypes = {
};
