import React from "react";
import PropTypes from "prop-types";
import useIsWeb from "../../hooks/useIsWeb";
import MobileStepLayout from "./mobile/MobileStepLayout";
import WebStepLayout from "./web/WebStepLayout";

const StepLayout = (({children})=>{
    const isWeb = useIsWeb();

    if(isWeb){
        return <WebStepLayout>{children}</WebStepLayout>
    }
    else{
        return <MobileStepLayout>{children}</MobileStepLayout>
    }
});

export default StepLayout;

StepLayout.propTypes = {
    children: PropTypes.any,
};
