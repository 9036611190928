import React from 'react';
import useIsWeb from "../../../hooks/useIsWeb";
import MobileYourInformation from "./mobile";
import WebYourInformation from "./web";

const YourInformation = (() => {
    const isWeb = useIsWeb();
    if(isWeb){
        return <WebYourInformation />
    }
    else{
        return <MobileYourInformation />
    }
});

export default YourInformation;

YourInformation.propTypes = {
};
