import React from "react";
import styled from "styled-components";
import msg, {get_msg_txt} from "../../../../../../consts/msg";
import RedStar from "../../../../RedStar";
import useLang from "../../../../../../hooks/useLang";
import CitySelect from "../shared/CitySelect";
import AreaSelect from "../shared/AreaSelect";
import RoadSelect from "../shared/RoadSelect";
import useIsShowAddressDetails from "../shared/useIsShowAddressDetails";
import AddressDetailsField from "../shared/AddressDetailsField";
import SharedRedText from "../shared/RedText";
import WholeRow from "../../../../WholeRow";

const Container = styled.div`
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 7.25rem 25rem;
  grid-template-areas: 
    "title note"
    ".     content";
`;

const Title = styled.div`
  grid-area: title;
  display: flex;
  align-items: center;
  color: #434242;
  font-size: 1rem;
`;

const Content = styled.div`
  grid-area: content;
`;

const RedText = styled(SharedRedText)`
  grid-area: note;
  display: flex;
  align-items: center;
`;

const WebPlaceRow = () => {
    const lang = useLang();
    const isShowAddressDetails = useIsShowAddressDetails();

    return <Container>
        <Title>
            {get_msg_txt(lang, msg.animal_address_title)}<RedStar />
        </Title>
        <RedText>
            {get_msg_txt(lang, msg.hint_for_animal_address)}
        </RedText>
        <Content>
            <CitySelect />
            <AreaSelect />
            <RoadSelect />
            {isShowAddressDetails && <WholeRow>
                <AddressDetailsField />
            </WholeRow>}
        </Content>
    </Container>
};

export default WebPlaceRow;
