import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {ON_CASE_FIELD_CHANGE} from "../../../../../actions/types";
import TextField from "../../../shared/TextField";
import PropTypes from "prop-types";

const CloudLinkField = (({className}) => {
    const dispatch = useDispatch();
    const cloud_link = useSelector(({case_one})=> case_one.cloud_link)

    return <TextField
        className={className}
        value={cloud_link}
        onChange={({target: {value}})=>{
            dispatch({
                type: ON_CASE_FIELD_CHANGE,
                columnName: "cloud_link",
                payload: value
            });
        }}
    />
});

export default CloudLinkField;

CloudLinkField.propTypes = {
    className: PropTypes.string
};
