import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import styled, {css} from "styled-components";
import _Select from "./Select";
import {ON_CASE_FIELD_CHANGE} from "../../../../actions/types";
import {DEFAULT_SELECT_VALUE} from "../../../../config";
import useIsWeb from "../../../../hooks/useIsWeb";

const Select = styled(_Select)`
  width: 100%;
  
  select{
    width: 11.875rem;
  
      ${props => props.isWeb && css`
        width: 100%;
      `};
  }
`

const SelectCaseOneColumn = (({className, columnName, options, onDirty=()=>{}}) => {
    const isWeb = useIsWeb();
    const dispatch = useDispatch();
    const value = useSelector(state => state.case_one[columnName]);

    return <Select
        isWeb={isWeb}
        className={className}
        options={options}
        value={value}
        id={columnName}
        onChange={value=>{
            if(value!==DEFAULT_SELECT_VALUE){
                onDirty();
            }

            dispatch({
                type: ON_CASE_FIELD_CHANGE,
                columnName,
                payload: value
            });
        }}
    />
});

export default SelectCaseOneColumn;

SelectCaseOneColumn.propTypes = {

};
