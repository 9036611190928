import React from "react";
import useIsWeb from "../../../hooks/useIsWeb";
import MobileSecondNotice from "./mobile";
import WebSecondNotice from "./web";

const SecondNotice = (()=>{
    const isWeb = useIsWeb();

    if(isWeb){
        return <WebSecondNotice />
    }
    else{
        return <MobileSecondNotice />
    }
});

export default SecondNotice;

SecondNotice.propTypes = {
};
