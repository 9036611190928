import React from "react";
import styled from "styled-components";
import useLang from "../../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import RadioButton from "./RadioButton";

const Container = styled.div`
  width: 11.375rem;
  height: 2.625rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const RadioButtons = ({className, checked, onChange}) => {
    const lang = useLang();

    return <Container className={className}>
        <RadioButton checked={checked} label={get_msg_txt(lang, msg.yes)} onChange={()=>{
            onChange(true);
        }} />
        <RadioButton checked={!checked} label={get_msg_txt(lang, msg.no)}  onChange={()=>{
            onChange(false);
        }} />
    </Container>
};

export default RadioButtons;
