import _ from 'lodash';
import React from 'react';
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";
import {ON_CASE_FIELD_CHANGE} from "../../../../../actions/types";
import TextField from "../../../shared/TextField";
import useLang from "../../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import Error from "../../../shared/Error";

const Container = styled.div`
  width: 100%;
`;

const ReportAgencyField = (() => {
    const lang = useLang();
    const dispatch = useDispatch();
    const report_agency = useSelector(({case_one})=> case_one.report_agency)

    return <Container>
        <TextField
            value={report_agency}
            onChange={({target: {value}})=>{
                dispatch({
                    type: ON_CASE_FIELD_CHANGE,
                    columnName: "report_agency",
                    payload: value
                });
            }}
        />
        <Error error={!_.isEmpty(report_agency) ? "" :get_msg_txt(lang, msg.reported_agency_hint)} />
    </Container>
});

export default ReportAgencyField;

ReportAgencyField.propTypes = {
};
