import React from "react";
import styled from 'styled-components';
import RedHallowCircle from "../../../../components/RedCircle";
import RedFullCircle from "../../../../components/RedFullCircle";
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";
import GreenCircle from "../../../../components/GreenCircle";
import useStep from "../../../../hooks/useStep";
import useIsThankYouRoute from "../../../../hooks/useIsThankYouRoute";

const Container = styled.div`
  height: 9.375rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InnerBox = styled.div`
  width: 33.125rem;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
`;

const Box = styled.div`
  position: relative;
  flex: 1;  
`

const Connector = styled.div`
  transition: all .25s ease;
  background-color: ${props => props.isGreen? "#79B256": "#F2ABAB"};
  height: 5px;
  
  position: absolute;
  flex: 1 1 auto;
  top: 8px;
  left: calc(-50% + 10px);
  right: calc(50% + 10px);
`;

const VerticalFlexBox = styled.span`
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Text = styled.div`
  font-size: 0.875rem;
  margin-top: 0.5rem;
`;

const Circle = ({index, currentStep})=>{
    const isThankYouRoute = useIsThankYouRoute();
    if(isThankYouRoute){
        return <GreenCircle />
    }
    if(index < currentStep){
        return <GreenCircle />
    }
    else if(index === currentStep){
        return <RedHallowCircle />
    }
    else{
        return <RedFullCircle />
    }
}

const Top = () => {
    const isThankYouRoute = useIsThankYouRoute();
    const currentStep = useStep();
    const lang = useLang();
    const arr = [msg.your_information, msg.information_and_animal, msg.photos_and_videos, msg.confirmation];

    return <Container>
        <InnerBox>
            {arr.map((text, index)=>{
                return <Box key={`Web-StepLayout-Top-${index}`}>
                    {index!==0 && <Connector isGreen={isThankYouRoute || index <= currentStep} />}
                    <VerticalFlexBox>
                        <Circle index={index} currentStep={currentStep} />

                        <Text>{get_msg_txt(lang, text)}</Text>
                    </VerticalFlexBox>
                </Box>
            })}
        </InnerBox>
    </Container>
};

export default Top;
