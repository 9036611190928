import _ from "lodash";
import {UPLOADED} from "../../../../consts/upload_status";
import useCaseOne from "../../../../hooks/useCaseOne";

const isReportDisabled = ({is_reported, report_agency})=>{
    return is_reported === true && _.isEmpty(report_agency);
}

const isFilesDisabled = ({case_files=[], cloud_link}) => {
    const noFiles = case_files.length === 0;
    const hasUploadedFiles = case_files.some(e => e.status === UPLOADED);
    const isCloudLinkEmpty = _.isEmpty(cloud_link);

    if(isCloudLinkEmpty){
        if(noFiles){
            return true;
        }
        else if(!hasUploadedFiles){
            return true;
        }
    }
    return false;
};

const isDisabled = ({case_files, cloud_link, is_reported, report_agency}) => {
    return isFilesDisabled({case_files, cloud_link}) || isReportDisabled({is_reported, report_agency});
};

const useIsNextDisabled = ()=>{
    const {is_reported, case_files, cloud_link, report_agency} = useCaseOne();
    return isDisabled({case_files, cloud_link, is_reported, report_agency});
}

export default useIsNextDisabled;
