import React, {useEffect, useState} from "react";
import styled from "styled-components";
import ImageButton from "../ImageButton";
import utils from "../../../../../utils/utils";

const Container = styled.img`
  width: 3.125rem;
  height: 3.125rem;
`;

const ImageFilePreview = (({className, file, isUploaded, src: uploadedSrc}) => {
    const [loading, setLoading] = useState(true);
    const [src, setSrc] = useState(null);

    useEffect(() => {
        const reader = new FileReader();

        const load = () => {
            setLoading(false);
            setSrc(reader.result);
        };

        reader.addEventListener("load", load, false);
        reader.readAsDataURL(file);

        return function () {
            reader.removeEventListener("load", load);
        };
    }, [file]);

    if(isUploaded){
        return <a href={utils.get_server_img_src(uploadedSrc)} target="_blank" rel="noopener noreferrer">
            <Container className={className} src={src} alt={file.name}/>
        </a>
    }

    return loading ? <ImageButton /> : <Container className={className} src={src} alt={file.name}/>;
});

export default ImageFilePreview;

ImageFilePreview.propTypes = {
};
