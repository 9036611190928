import './main.css';
import React from 'react';
import {Route, Switch} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import store from './store';
import history from './history';
import routes from './routes';
import ErrorRoute from './routes/Error_Route';
import SPCATheme from "./themes/SPCATheme";
import {QueryClient, QueryClientProvider} from "react-query";

export default (()=>{
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
                refetchOnReconnect: false
            }
        }
    });

    return <QueryClientProvider client={queryClient}>
        <Provider store={store()}>
        <ConnectedRouter history={history}>
            <SPCATheme>
                <CssBaseline>
                    <Switch>
                        {
                            routes.map(({path, exact = false, has_blue_footer=false, has_copyright_footer=false, component, layout}, index) => {
                                return <Route
                                    key={`ROUTE_${index}`}
                                    exact={exact}
                                    path={path}
                                    layout={layout}
                                    component={component}
                                />
                            })
                        }
                        <Route exact component={ErrorRoute} />
                    </Switch>
                </CssBaseline>
            </SPCATheme>
        </ConnectedRouter>
    </Provider>
    </QueryClientProvider>
});
