import React from "react";
import styled from 'styled-components';
import msg, {get_msg_txt} from "../../../../../../../consts/msg";
import useLang from "../../../../../../../hooks/useLang";

const Container = styled.span`
  color: #CB1212;
  font-size: 0.6875rem;
`;

const HintText = ()=>{
    const lang = useLang();

    return <Container>
        {get_msg_txt(lang, msg.details_hint)}
    </Container>
}

export default HintText;
