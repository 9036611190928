import _ from 'lodash';
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import TextField from "../../../../../shared/TextField";
import msg, {get_msg_txt} from "../../../../../../../consts/msg";
import {ON_CASE_FIELD_CHANGE} from "../../../../../../../actions/types";
import Error from "../../../../../shared/Error";

const AddressDetailsField = () => {
    const dispatch = useDispatch();
    const {lang, value} = useSelector(({app, case_one})=> {
        const {animal_location_details} = case_one;
        return {lang: app.lang, value: animal_location_details};
    });
    const error = _.isEmpty(value) ? get_msg_txt(lang, msg.animal_location_details_error) : "";

    return <div>
        <TextField
            isRequired
            value={value}
            error={error}
            placeholder={get_msg_txt(lang, msg.animal_location_details_placeholder)}
            onChange={({target: {value}})=>{
                dispatch({
                    type: ON_CASE_FIELD_CHANGE,
                    columnName: "animal_location_details",
                    payload: value
                });
            }}
        />
        <Error error={error} />
    </div>
};

export default AddressDetailsField;
