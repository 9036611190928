import React, {memo} from "react";
import styled from "styled-components";
import mail_icon from "./mail.svg";
import phone_icon from "./phone.svg";
import {EMAIL, TEL} from "../../../../config";
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
`;

const Text = styled.div`
  font-size: 0.75rem;
  text-align: center;
`;

const Bottom = memo(({className})=>{
    const lang = useLang();

    return <Container className={className}>
        <div>
            <a title={TEL} style={{marginRight: 28}} href={`tel:${TEL}`}>
                <img alt={TEL} src={phone_icon} width={50} height={50} />
            </a>
            <a title={EMAIL} href={`mailto:${EMAIL}`}>
                <img alt={EMAIL} src={mail_icon} width={50} height={50} />
            </a>
            <Text>{get_msg_txt(lang, msg.contact_us)}</Text>
        </div>
    </Container>
});

export default Bottom;

Bottom.propTypes = {
};
