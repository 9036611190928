import React from "react";
import styled from "styled-components";
import SightingRow from "../shared/SightingRow";
import PlaceRow from "../shared/PlaceRow";
import AnimalDetails from "../shared/AnimalDetails";
import DetailsRow from "../shared/DetailsRow";
import useHasError from "../shared/useHasError";
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";
import SubmitBoxRow from "../../shared/SubmitBoxRow";

const Container = styled.div`
  width: 39.75rem;
`;

const WebAnimalInformation = () => {
    const disabled = useHasError();
    const lang = useLang();

    return <Container>
        <SightingRow />
        <PlaceRow />
        <AnimalDetails />
        <DetailsRow />
        <SubmitBoxRow text={get_msg_txt(lang, msg.next_step)} disabled={disabled} />
    </Container>
};

export default WebAnimalInformation;
