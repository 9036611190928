import React, {useState} from 'react';
import {useSelector} from "react-redux";
import SelectRow from "../../../shared/SelectRow";
import {FEMALE, MALE} from "../../../../../consts/gender";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import {DEFAULT_SELECT_VALUE} from "../../../../../config";
import utils from "../../../../../utils/utils";

const columnName = "whistleblower_gender";

const GenderField = (() => {
    const [dirty, onDirty] = useState(false);
    const {lang, value} = useSelector(({app, case_one}) => {
        return {lang: app.lang, value: case_one[columnName]};
    });

    const options = [
        {value: DEFAULT_SELECT_VALUE, label: get_msg_txt(lang, msg.select_default_label)},
        {value: MALE, label: utils.get_gender_label({lang, gender: MALE})},
        {value: FEMALE, label: utils.get_gender_label({lang, gender: FEMALE})}
    ]

    const error = value === DEFAULT_SELECT_VALUE ? get_msg_txt(lang, msg.gender_error) : "";

    return <SelectRow
        isRequired
        columnName={columnName}
        label={get_msg_txt(lang, msg.gender)}
        options={options}
        error={!dirty? "" :error}
        value={value}
        onDirty={()=>onDirty(true)}
    />
});

export default GenderField;

GenderField.propTypes = {
};
