import uuid from "uuid";
import _ from "lodash";
import appendQuery from "append-query";
import fileExtension from 'file-extension';
import moment from 'moment-timezone';
import {push} from 'connected-react-router';
import queryString from 'query-string';
import {DEFAULT_LANG, IMG_PREFIX_URL} from '../config';
import msg, {get_msg_txt} from "../consts/msg";
import {MALE} from "../consts/gender";

export default class utils {
    static ObjectId() {
        return uuid();
    }
    static get_error_obj({response}){
        if(!_.isEmpty(response) && !_.isEmpty(response.data) && _.isNumber(response.data.errorCode)){
            const {errorCode, msg, info = {}} = response.data;
            return {errorCode, msg, info};
        }
        else{
            return {};
        }
    }
    static get_server_img_src(src=""){
        return `${IMG_PREFIX_URL}/${src}`;
    }

    static is_email_format(value=""){
        const regex = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(value);
    }

    static is_phone_format(value=""){
        const regex = /([0-9]{7,8})(?:(?:#)([0-9]+))?$/;
        return regex.test(value);
    }

    static redirect_route(href=""){
        window._store.dispatch(push(href));
    }

    static get_query(search){
        return queryString.parse(search);
    }

    static get_filters(search){
        if(_.isEmpty(search)){
            search = window.location.search;
        }

        const query = this.get_query(search);
        if(_.isEmpty(query) || _.isEmpty(query.filters)){
            return {};
        }
        else{
            try{
                const obj = JSON.parse(query.filters);
                return _.isEmpty(obj) ? {} : obj;
            }
            catch(e){
                return {};
            }
        }
    }

    static get_value_by_cur_filters({filters, field, default_value}){
        if(_.isEmpty(field) || _.isEmpty(filters) || !filters.hasOwnProperty(field)){
            return default_value;
        }
        else{
            const {value, type} = filters[field];
            if(type === "String" && _.isEmpty(value)){
                return default_value;
            }
            else{
                return value;
            }
        }
    }

    static ISO_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ssZ';

    static CONFIRMATION_DATE_FORMAT = 'YYYY-MM-DD';

    static TAIPEI_TIMEZONE = 'Asia/Taipei';

    static get_now_taipei_str(){
        return moment(new Date()).tz(this.TAIPEI_TIMEZONE).format(this.ISO_DATE_FORMAT);
    }

    static get_now_taipei_date(){
        return moment(new Date().setHours(0,0,0,0)).tz(this.TAIPEI_TIMEZONE).toDate();
    }

    static get_taipei_date(str=""){
        return moment.tz(str, this.TAIPEI_TIMEZONE).toDate().toISOString();
    }

    static get_confirmation_date_format(taipei_str=""){
        return moment(taipei_str).format(this.CONFIRMATION_DATE_FORMAT);
    }

    static is_img_filename(src="") {
        if(_.isEmpty(src)) return false;

        const image_extensions = ["jpeg", "jpg", "gif", "png"];
        const ext = fileExtension(src);
        return image_extensions.indexOf(ext)!==-1;
    }

    static clear_pagination({filters={}}){
        delete filters.page;
        return filters;
    }

    static changeParams({route_path, filters={}, property="", type="String", value}){
        filters = utils.clear_pagination({filters});

        if(value == null){
            delete filters[property];
        }
        else if(type==="String" && _.isEmpty(value)){
            delete filters[property];
        }
        else if(type==="Number" && value === 0){
            delete filters[property];
        }
        else if(type==="Array" && value.length===0){
            delete filters[property];
        }
        else{
            filters[property] = {property, value, type};
        }

        if(_.isEmpty(filters)){
            utils.redirect_route(route_path);
        }
        else{
            utils.redirect_route(appendQuery(route_path, {filters: JSON.stringify(filters)}, {removeNull: true}));
        }
    }

    static getFileSizeInMB(file){
        return file.size / 1024 / 1024;
    }

    static get_gender_label({lang=DEFAULT_LANG, gender=MALE}){
        return gender=== MALE ? get_msg_txt(lang, msg.gender_male): get_msg_txt(lang, msg.gender_female)
    }
}
