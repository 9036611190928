import React from "react";
import styled from "styled-components";
import logo from "./spca_logo.svg";
import {APP_NAME, HOME_URL} from "../../config";

const Container = styled.a`
    display: inline-block;
`;

const Logo = ({className}) => {
    return <Container href={HOME_URL}>
        <img className={className} alt={APP_NAME} src={logo} />
    </Container>
};

export default Logo;
