import React from "react";
import styled from "styled-components";
import PrevStepButton from "../../PrevStepButton";
import NextStepButton from "../../NextStepButton";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SubmitBoxRow = ({text, disabled}) => {
    return <Container>
        <PrevStepButton />
        <NextStepButton text={text} disabled={disabled} />
    </Container>
};

export default SubmitBoxRow;