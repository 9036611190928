import React from 'react';
import styled from 'styled-components';
import EmailField from "../mobile/EmailField";
import PhoneField from "../mobile/PhoneField";
import LineIDField from "../mobile/LineIDField";
import GenderField from "../mobile/GenderField";
import AgeField from "../mobile/AgeField";
import SourceField from "../mobile/SourceField";
import NameField from "../mobile/NameField";
import SubmitBoxRow from "../../shared/SubmitBoxRow";
import useLang from "../../../../hooks/useLang";
import useHasError from "../shared/useHasError";
import msg, {get_msg_txt} from "../../../../consts/msg";

const Container = styled.div`
  width: 31.25rem;
`;

const WebYourInformation = (() => {
    const lang = useLang();
    const disabled = useHasError();

    return <Container>
        <NameField />
        <EmailField />
        <PhoneField />
        <LineIDField />
        <GenderField />
        <AgeField />
        <SourceField />
        <SubmitBoxRow disabled={disabled} text={get_msg_txt(lang, msg.next_step)} />
    </Container>
});

export default WebYourInformation;

WebYourInformation.propTypes = {
};
