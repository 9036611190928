import {APP_CHANGE_LANG, APP_INIT, APP_INIT_ERROR, ON_NEXT_STEP, ON_PREV_STEP, ON_STEP_CHANGE} from "../actions/types";
import {DEFAULT_STEP, next_step, prev_step} from '../consts/step';
import {DEFAULT_LANG} from '../config';

const INITIAL_STATE = {
    is_loaded: false,
    lang: DEFAULT_LANG,
    step: DEFAULT_STEP
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case APP_INIT: {
            return { ...state, is_loaded: true};
        }
        case APP_INIT_ERROR: {
            return {...state, is_loaded: true};
        }
        case APP_CHANGE_LANG:{
            const {payload} = action;
            return {...state, lang: payload};
        }
        case ON_STEP_CHANGE:{
            const step = action.payload;
            return {...state, step};
        }
        case ON_NEXT_STEP:{
            const step = next_step(state.step);
            return {...state, step};
        }
        case ON_PREV_STEP:{
            const step = prev_step(state.step);
            return {...state, step};
        }
        default:
            return state;
    }
}
