import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import msg, {get_msg_txt} from '../../../../../consts/msg';
import useLang from "../../../../../hooks/useLang";
import uploadIcon from "./upload_icon.svg";

const Container = styled.img`
  width: 50px;
  
  ${props => !props.isWeb && css`
      width: 1.5rem;
      height: 1rem;
  `};
`;

const UploadIcon = (({isWeb}) => {
    const lang = useLang();

    return <Container
        isWeb={isWeb}
        alt={get_msg_txt(lang, msg.upload_btn_label)}
        src={uploadIcon}
    />
});

export default UploadIcon;

UploadIcon.propTypes = {
    isWeb: PropTypes.bool
};
