import useLang from "../../../../hooks/useLang";
import useCaseOne from "../../../../hooks/useCaseOne";
import utils from "../../../../utils/utils";
import {UPLOADED} from "../../../../consts/upload_status";
import getReportText from "./getReportText";
import getIsPersonalInfoText from "./getIsPersonalInfoText";
import msg, {get_msg_txt} from "../../../../consts/msg";

const useThirdRows = () => {
    const lang = useLang();
    const { case_files=[], is_reported, report_agency, is_personal_info, cloud_link} = useCaseOne();

    return case_files.filter(e => e.status === UPLOADED).map(({file: {name}, src}) => {
        return {
            isImg: true,
            name,
            url: utils.get_server_img_src(src)
        };
    })
    .concat([{isText: true, text: `${get_msg_txt(lang, msg.cloud_link)} : ${cloud_link}`}])
    .concat([{isText: true, text: getReportText({lang, is_reported, report_agency})}])
    .concat([{isText: true, text: getIsPersonalInfoText({lang, is_personal_info})}]);
};

export default useThirdRows;