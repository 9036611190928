import React from "react";
import styled from "styled-components";
import useLang from "../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../consts/msg";
import _TelIcon from "../../../components/TelIcon";
import EmailIcon from "../../../components/EmailIcon";
import Logo from "../../../components/Logo";

const Container = styled.header`
  display: flex;
  justify-content: center;
  width: 100vw;
  height: 3.5rem;
  background-color: #FFFFFF;
`

const Wrapper = styled.div`
  width: 18.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Left = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Text = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  font-size: 1rem;
  height: 32px;
  font-weight: bold;
  margin-left: 10px;
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TelIcon = styled(_TelIcon)`
  margin-right: 1.5rem;
`;

const Header = () => {
    const lang = useLang();

    return <Container>
        <Wrapper>
            <Left>
                <Logo />
                <Text>{get_msg_txt(lang, msg.report_system)}</Text>
            </Left>
            <Right>
                <TelIcon />
                <EmailIcon />
            </Right>
        </Wrapper>
    </Container>
};

export default Header;
