import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.ul`
  width: 18.875rem;
  min-height: 44rem;
  background-color: #FFFDFD;
  border-radius: 7px;
  
  list-style: none;
  margin-top: 1.5625rem;
  padding: 1.875rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  li:not(:last-of-type):after {
    content: "";
    display: block;
    height: 1.875rem;
  }
`;

const WhiteBoard = (({className, children=""})=>{
    return <Container className={className}>
        {children}
    </Container>
});

export default WhiteBoard;

WhiteBoard.propTypes = {
    className: PropTypes.string,
    buttonText: PropTypes.string,
};
