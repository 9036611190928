import {useEffect} from 'react';
import useStep from "./useStep";

const useScrollToTop = () => {
    const step = useStep();

    useEffect(() => {
        document.getElementById("root").scrollTo(0, 0);
    }, [step]);
    return null;
};

export default useScrollToTop;