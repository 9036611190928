import React from "react";
import styled from "styled-components";
import msg, {get_msg_txt} from "../../../../consts/msg";
import _GlobalSelect from "../../../../components/GlobalSelect";
import _Logo from "../../../../components/Logo";
import useLang from "../../../../hooks/useLang";

const Container = styled.header`
  position: relative;
  display: flex;
  justify-content: center;
  width: 44rem;
  margin-top: 1.25rem;
`;

const Middle = styled.div`
  display: flex;
  justify-content: center;
`;

const Logo = styled(_Logo)`
  width: 5rem;
  height: 3.125rem;
`

const Text = styled.div`
  font-weight: bold;
  margin-left: 1.125rem;
  font-size: 1.5625rem;
  
  display: flex;
  align-items: center;
`;

const GlobalSelect = styled(_GlobalSelect)`
    position: absolute;
    right: 0;
    height: 100%;      
`;

const WebHeader = () => {
    const lang = useLang();

    return <Container>
        <Middle>
            <Logo />
            <Text>
                {get_msg_txt(lang, msg.report_system)}
            </Text>
        </Middle>
        <GlobalSelect />
    </Container>
};

export default WebHeader;
