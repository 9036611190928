import React, {memo} from "react";
import _ from "lodash";
import PropTypes from "prop-types";

const ErrorMsg = memo(({children=<div>發生錯誤</div>})=>{
    if(_.isEmpty(children)){
        return null;
    }
    else{
        return (
            <div className='error-page-container'>
                {children}
            </div>
        );
    }
});

export default ErrorMsg;

ErrorMsg.propTypes = {
    children: PropTypes.any
};
