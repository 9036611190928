import React from "react";
import styled from "styled-components";
import {useDispatch} from "react-redux";
import useDetails from "./useDetails";
import {ON_CASE_FIELD_CHANGE} from "../../../../../../actions/types";

const Container = styled.textarea`
  width: 100%;
  height: 8.75rem;
  border: solid 1px #AFAEAE;
  border-radius: 5px;
  padding: 10px;
  resize: none;
  appearance: none;
  font: inherit;
  
  &:focus{
    outline: none;
  }
`;

const TextArea = ({className}) => {
    const dispatch = useDispatch();
    const details = useDetails();

    return <Container
        className={className}
        value={details}
        onChange={({target: {value}})=>{
            dispatch({
                type: ON_CASE_FIELD_CHANGE,
                columnName: "details",
                payload: value
            });
        }}
    />
};

export default TextArea;
