import React from 'react';
import useIsWeb from "../../../../../hooks/useIsWeb";
import WebAnimalDetails from "./web";
import MobileAnimalDetails from "./mobile";

const AnimalDetails = (() => {
    const isWeb = useIsWeb();

    if(isWeb){
        return <WebAnimalDetails />
    }
    else{
        return <MobileAnimalDetails />
    }
});

export default AnimalDetails;

AnimalDetails.propTypes = {

};
