import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import DateField from "../../../../fields/DateField";
import {ON_CASE_FIELD_CHANGE} from "../../../../../actions/types";
import WholeRow from "../../../WholeRow";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import RedStar from "../../../RedStar";
import useLang from "../../../../../hooks/useLang";
import styled, {css} from "styled-components";
import useIsWeb from "../../../../../hooks/useIsWeb";

const Container = styled.div`
  ${props => props.isWeb && css`
      display: grid;
      grid-template-columns: 7.25rem 25rem;
  `};
`;

const Text = styled.div`
  color: #434242;
  font-size: 1rem;
`;

const SightingRow = (() => {
    const lang = useLang();
    const isWeb = useIsWeb();
    const time_of_sighting = useSelector(state=>state.case_one.time_of_sighting);
    const dispatch = useDispatch();

    return <Container isWeb={isWeb}>
        <WholeRow>
            <Text>
                {get_msg_txt(lang, msg.time_of_sighting)}
                <RedStar />
            </Text>
        </WholeRow>
        <WholeRow>
            <DateField
                value={time_of_sighting}
                onChange={value=>{
                    dispatch({
                        type: ON_CASE_FIELD_CHANGE,
                        columnName: "time_of_sighting",
                        payload: value
                    });
                }}
            />
        </WholeRow>
    </Container>
});

export default SightingRow;

SightingRow.propTypes = {
};
