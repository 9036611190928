import React from 'react';
import styled from 'styled-components';
import CaseTable from './CaseTable';
import Header from "./Header";

const Container = styled.div`
  background-color: #DCE5F4;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100%;
`;

const Box = styled.div`
  width: 83.3333333%;
  margin-top: 2rem;
  margin-bottom: 4rem;
`;

const Announcement = (() => {
    return <Container>
        <Box>
            <Header />
            <CaseTable />
        </Box>
    </Container>
});

export default Announcement;

Announcement.propTypes = {
};
