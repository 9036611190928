import React, {useState} from 'react';
import {useSelector} from "react-redux";
import SelectRow from "../../../shared/SelectRow";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import {DEFAULT_SELECT_VALUE} from "../../../../../config";

const columnName = "whistleblower_source";

export const options = [
    {
        value: DEFAULT_SELECT_VALUE,
        msg: msg.select_default_label
    },
    {
        value: "WEB_SEARCH",
        msg: msg.source_web_search
    },
    {
        value: "TSPCA_WEBSITE",
        msg: msg.source_tspca_website
    },
    {
        value: "TSPCA_IG",
        msg: msg.source_tspca_ig
    },
    {
        value: "TSPCA_FB",
        msg: msg.source_tspca_fb
    },
    {
        value: "FRIENDS",
        msg: msg.source_tspca_friends
    },
]

const SourceField = (() => {
    const [dirty, onDirty] = useState(false);
    const {lang, value} = useSelector(({app, case_one}) => {
        return {lang: app.lang, value: case_one[columnName]};
    });
    const error = value === DEFAULT_SELECT_VALUE ? get_msg_txt(lang, msg.information_source_error) : "";
    const _options = options.map(({value, msg})=>{
        return {
            value,
            label: get_msg_txt(lang, msg)
        };
    })

    return <SelectRow
        isRequired
        label={get_msg_txt(lang, msg.information_source)}
        columnName={columnName}
        options={_options}
        value={value}
        error={!dirty? "" :error}
        onDirty={()=>onDirty(true)}
    />
});

export default SourceField;

SourceField.propTypes = {
};
