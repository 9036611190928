import React from 'react';
import useIsWeb from "../../../hooks/useIsWeb";
import WebPhotosAndVideos from "./web/WebPhotosAndVideos";
import MobilePhotosAndVideos from "./mobile/MobilePhotosAndVideos";

const PhotosAndVideos = (() => {
    const isWeb = useIsWeb();
    if(isWeb){
        return <WebPhotosAndVideos />
    }
    else{
        return <MobilePhotosAndVideos />
    }
});

export default PhotosAndVideos;

PhotosAndVideos.propTypes = {
};
