import React from "react";
import PropTypes from "prop-types";
import useIsWeb from "../../hooks/useIsWeb";
import WebNoticeLayout from "./web";
import MobileNoticeLayout from "./mobile";

const NoticeLayout = (({children})=>{
    const isWeb = useIsWeb();

    if(isWeb){
        return <WebNoticeLayout>{children}</WebNoticeLayout>
    }
    else{
        return <MobileNoticeLayout>{children}</MobileNoticeLayout>
    }
});

export default NoticeLayout;

NoticeLayout.propTypes = {
    children: PropTypes.any,
};
