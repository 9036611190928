import _ from "lodash";
import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import useTheme from "@material-ui/core/styles/useTheme";

const styles = {
    ListItemText: {
        overflow: "auto"
    }
};

function MultipleSelect(props){
    const {id, onChange= ()=>{}, onClose=()=>{}, className, classes = {}, label = "", options = [], values = []} = props;
    const [open, setOpen] = useState(false);
    const theme = useTheme();

    const optionMap = options.reduce( (obj, option)=>{
        return {...obj, [option.value]: option}
    },{});
    const labelMarginTop = -theme.spacing(2);

    return (
        <FormControl className={className}>
            {label && <InputLabel style={{marginTop: labelMarginTop}} id={id}>{label}</InputLabel>}
            <Select
                labelId={id}
                style={{marginTop: 0}}
                multiple
                value={values}
                onChange={(event)=>onChange(event.target.value)}
                open={open}
                onOpen={()=>{
                    setOpen(true);
                }}
                onClose={()=>{
                    setOpen(false);
                    onClose();
                }}
                label={label}
                input={<Input id={`${id}_input`} />}
                renderValue={(values=[])=> values.map(value=> optionMap[value].label || "").join(', ')}
            >
                {_.values(options).map((option={}) => {
                    const {label, value} = option;
                    const checked = values.indexOf(value) !== -1;

                    return <MenuItem key={value} value={value}>
                        <Checkbox checked={checked} />
                        <ListItemText className={classes.ListItemText} primary={label} />
                    </MenuItem>
                })}
            </Select>
        </FormControl>
    );
}

MultipleSelect.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    classes: PropTypes.object,
    theme: PropTypes.object,
    label: PropTypes.string,
    values: PropTypes.array,
    options: PropTypes.array,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
};

export default withStyles(styles)(MultipleSelect);
