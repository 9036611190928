import React from 'react';
import styled from "styled-components";
import {HiExclamationCircle as _HiExclamationCircle} from "react-icons/hi";

const Container = styled.div`
  grid-area: error;
  font-size: .75rem;
  color: #CB1212;
  margin-top: 4px;
  display: flex;
  align-items: center;
`;

const HiExclamationCircle = styled(_HiExclamationCircle)`
  font-size: 0.8125rem;
`

const Text = styled.div`
  font-size: 0.625rem;
`;

const Error = (({error}) => {
    return <Container>
        {error && <HiExclamationCircle />}
        <Text>
            {error}
        </Text>
    </Container>
});

export default Error;

Error.propTypes = {
};
