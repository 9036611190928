import React from "react";
import styled from "styled-components";
import msg, {get_msg_txt} from "../../../../consts/msg";
import TelIcon from "../../../TelIcon";
import EmailIcon from "../../../EmailIcon";
import useLang from "../../../../hooks/useLang";
import {EMAIL, TEL} from "../../../../config";

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Left = styled.div`
  display: flex;
  align-items: flex-start;
  font-size: 0.875rem;
  font-weight: bold;
`;

const Right = styled.div`
  display: grid;
`;

const Row = styled.div`
  display: flex;
  
  &:first-child{
    margin-bottom: 10px;
  }
`;

const Link = styled.a`
    margin-left: 2.5rem;
    text-decoration: none;
    color: #000000;
`;

const BottomNotice = () => {
    const lang = useLang();

    return <Container>
        <Left>
            {get_msg_txt(lang, msg.contact_us)}
        </Left>
        <Right>
            <Row>
                <TelIcon />
                <Link title={TEL} href={`tel:${TEL}`}>{TEL}</Link>
            </Row>
            <Row>
                <EmailIcon />
                <Link title={EMAIL} href={`mailto:${EMAIL}`}>{EMAIL}</Link>
            </Row>
        </Right>
    </Container>
};

export default BottomNotice;