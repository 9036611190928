import React from "react";
import msg, {get_msg_txt} from "../../../../../../../consts/msg";
import RedStar from "../../../../../RedStar";
import useLang from "../../../../../../../hooks/useLang";

const Title = () => {
    const lang = useLang();

    return <>
        {get_msg_txt(lang, msg.details)}
        <RedStar />
    </>
};

export default Title;
