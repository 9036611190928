import React, {Fragment} from "react";
import styled from "styled-components";
import WholeRow from "../../../../../WholeRow";
import useAreaAjax from "./useAreaAjax";
import msg, {get_msg_txt} from "../../../../../../../consts/msg";
import _Select from "../../../../../shared/SelectRow/SelectCaseOneColumn";
import {DEFAULT_CITY_VALUE} from "../CitySelect";
import useLang from "../../../../../../../hooks/useLang";
import useCaseOne from "../../../../../../../hooks/useCaseOne";

const Select = styled(_Select)`
  select{
    width: 100%;
  }
`;

export const DEFAULT_AREA_VALUE = "DEFAULT_AREA_VALUE";

const AreaSelect = () => {
    const lang = useLang();
    const {animal_location_city} = useCaseOne();
    const {isLoading, data} = useAreaAjax({animal_location_city});
    const isCitySelected = animal_location_city !== DEFAULT_CITY_VALUE;

    if(!isCitySelected || isLoading) return <Fragment />

    const area_list = data.data;
    const options = [{label: get_msg_txt(lang, msg.animal_location_area_list_choose), value: DEFAULT_AREA_VALUE}].concat(Object.keys(area_list).map(value=>{
        return {value, label: value};
    }));

    return <WholeRow>
        <Select
            columnName="animal_location_area"
            options={options}
        />
    </WholeRow>
};

export default AreaSelect;
