import {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {APP_INIT} from "../../../actions/types";

const useIsLoaded = (()=>{
    const is_loaded = useSelector(state => state.app.is_loaded);
    const dispatch = useDispatch();
    const init = useCallback(()=>{
        dispatch({
            type: APP_INIT,
            payload: {}
        });
    }, [dispatch]);

    useEffect(()=>{
        init();
    }, [is_loaded, init]);

    return is_loaded;
});

export default useIsLoaded;

useIsLoaded.propTypes = {
};
