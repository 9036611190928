import _ from 'lodash';
import React, {Fragment} from 'react';
import styled from 'styled-components';
import {Link as _Link} from 'react-router-dom';
import {EN, ZH_TW} from './lang';
import {ANNOUNCEMENT_URL, DEFAULT_LANG, MAX_NUMBER_OF_UPLOAD_FILES} from '../config';
import Tel1 from '../components/ThankYou/shared/Tel1';
import Tel2 from '../components/ThankYou/shared/Tel2';

const Link = styled(_Link)`
  color: #2355DC;
  text-decoration: none;
  
  &:hover{
    text-decoration: underline;
  }
`

const Text = styled.span`
  color: #C70A0A;
  font-size: 1.125rem;
`;

const msg = {
    lang: {
        [ZH_TW]: "中",
        [EN]: "E"
    },
    lang_web: {
        [ZH_TW]: "中文",
        [EN]: "English"
    },
    notice: {
        [ZH_TW]: "注意事項",
        [EN]: "NOTICE"
    },
    notice_contents1: {
        [ZH_TW]: [
            "協會目前僅處理<b>有飼主的動物案件</b>，流浪動物請尋求其他團體或當地動保主管機關協助。",
            "協會因人力、動物安置空間有限，且調查部門有一定排程，<b>無法受理即時救援的案件</b>，若動物有危及生命狀況且您無法現場自行提供協助，請先通報當地動保處或防疫所，或是撥打1999。",
            "目前<b>不接受網路轉貼通報案件</b>，為了取得更正確的消息與後續追蹤，請由原始貼文者報案，或是如果您可以與飼主溝通、提供像是醫療、安置等後續協助，才可進行通報。"
        ],
        [EN]: [
            "Due to the large amount of cases we receive on a daily basis and with a shortage of staff and resources, we currently only accept cases involving animals that are kept by people. Please seek out the help of other organizations or local animal protection offices for cases involving stray animals.",
            "We currently do not have the capacity to conduct emergency rescues. If the animal is in immediate danger and you are unable to provide assistance, please contact the local animal protection/animal health inspection office OR call 1999 for help.",
            "We currently do not accept the forwarding of cases posted on the Internet unless you can provide subsequent support such as helping to communicate with the keeper of the animal, medical support, or foster care. If not, please ask the writer of the original post to contact us directly."
        ]
    },
    notice_contents2: {
        [ZH_TW]: [
            "需要<b>提供詳細位置資訊、動物相關影片、照片或錄音檔。</b>",
            "為取得更詳盡資訊、或更新進度，調查專員將透過電話、電子郵件與您聯繫，敬請<b>填寫正確連絡資訊</b>。",
            "對於因為資訊不足而無法成案的案件，建議可以直接與調查員聯繫，提供您蒐證技巧、和飼主溝通之方法等相關資訊與建議，在現場的您就是最能幫助到動物的人！"
        ],
        [EN]: [
            "We can not accept a case if you do not provide the exact address, relevant photos/videos/or sound recordings.",
            "Our inspectors may contact you through telephone and/or email to find out more about your reported case and/or provide you with a follow-up. Please provide accurate contact information or we may not accept your report.",
            "For reports that are not accepted due to a lack of information, you can still contact our inspectors via telephone or Email and we can provide you with tips on how to collect evidence or ways to communicate with the keeper of the animal to find out more information. Sometimes, you are the person who can help the animal right away."
        ]
    },
    copyright_footer:{
        [ZH_TW]: ()=><Fragment>若進行通報時有操作上的問題 ，請聯繫 <Tel1 /> 或 Email <a test_id="copyright_footer_email" href="mailto:investigations@spca.org.tw">investigations@spca.org.tw</a></Fragment>,
        [EN]: ()=><Fragment>If you encounter any problems while filling out the form, please contact us at <Tel1 /> or Email <a test_id="copyright_footer_email" href="mailto:investigations@spca.org.tw">investigations@spca.org.tw</a>.</Fragment>
    },
    submit_button_label: {
        [ZH_TW]: "了解",
        [EN]: "ok"
    },
    start_report: {
        [ZH_TW]: "開始報案",
        [EN]: "Starts Report"
    },
    your_information: {
        [ZH_TW]: "通報人資訊",
        [EN]: "Your Information"
    },
    information_and_animal: {
        [ZH_TW]: "動物資訊",
        [EN]: "Animal Information"
    },
    photos_and_videos: {
        [ZH_TW]: "影像上傳",
        [EN]: "Photos & Videos"
    },
    photos_and_videos_title: {
        [ZH_TW]: "上傳相關照片/影片/音檔",
        [EN]: "Uploads"
    },
    confirmation:{
        [ZH_TW]: "確認",
        [EN]: "Confirmation"
    },
    pre_step: {
        [ZH_TW]: "上一步",
        [EN]: "Back"
    },
    edit_step: {
        [ZH_TW]: "修改",
        [EN]: "Edit"
    },
    submit_btn: {
        [ZH_TW]: "送出",
        [EN]: "Submit"
    },
    confirmed_btn: {
        [ZH_TW]: "確認",
        [EN]: "Confirmed"
    },
    next_step: {
        [ZH_TW]: "下一步",
        [EN]: "Next"
    },
    name: {
        [ZH_TW]: "姓名",
        [EN]: "Name"
    },
    name_placeholder: {
        [ZH_TW]: "王小明",
        [EN]: "John Doe"
    },
    name_error: {
        [ZH_TW]: "請填寫完整姓名",
        [EN]: "Please provide your name"
    },
    email: {
        [ZH_TW]: "信箱",
        [EN]: "Email"
    },
    email_placeholder: {
        [ZH_TW]: "johndoe@mail.com",
        [EN]: "johndoe@mail.com"
    },
    email_error: {
        [ZH_TW]: "請填寫正確信箱",
        [EN]: "Please provide your email"
    },
    email_format_error: {
        [ZH_TW]: "信箱格式不正確",
        [EN]: "The email format is incorrect"
    },
    contact_us:{
        [ZH_TW]: "如有通報疑問請聯繫我們",
        [EN]: "Contact us"
    },
    phone: {
        [ZH_TW]: "電話",
        [EN]: "Phone"
    },
    phone_placeholder: {
        [ZH_TW]: "0935758068",
        [EN]: "+886935758068",
    },
    phone_error: {
        [ZH_TW]: "請輸入電話",
        [EN]: "Please provide your telephone"
    },
    phone_format_error: {
        [ZH_TW]: "電話格式錯誤",
        [EN]: "The Phone format is incorrect"
    },
    gender: {
        [ZH_TW]: "性別",
        [EN]: "Gender"
    },
    gender_male: {
        [ZH_TW]: "男",
        [EN]: "Male"
    },
    gender_female: {
        [ZH_TW]: "女",
        [EN]: "Female"
    },
    gender_error: {
        [ZH_TW]: "請選擇性別",
        [EN]: "Please provide your gender"
    },
    age: {
        [ZH_TW]: "年齡",
        [EN]: "Age"
    },
    age_error: {
        [ZH_TW]: "請提供年齡",
        [EN]: "Please provide your age"
    },
    information_source: {
        [ZH_TW]: "得知通報管道",
        [EN]: "Information source"
    },
    information_source_error: {
        [ZH_TW]: "請提供得知通報管道",
        [EN]: "Please let us know where is the information source"
    },
    source_web_search: {
        [ZH_TW]: "網頁搜尋關鍵字",
        [EN]: "Web Search"
    },
    source_tspca_website: {
        [ZH_TW]: "TSPCA官網",
        [EN]: "TSPCA website"
    },
    source_tspca_ig: {
        [ZH_TW]: "TSPCA IG",
        [EN]: "TSPCA IG"
    },
    source_tspca_fb: {
        [ZH_TW]: "TSPCA 臉書",
        [EN]: "TSPCA FB"
    },
    source_tspca_friends: {
        [ZH_TW]: "親朋好友、網友推薦",
        [EN]: "FRIENDS"
    },
    animal_type: {
        [ZH_TW]: "動物種別",
        [EN]: "Type of Animal"
    },
    animal_count: {
        [ZH_TW]: "動物數量",
        [EN]: "Number of Animals"
    },
    animal_type_placeholder: {
        [ZH_TW]: "狗",
        [EN]: "Dog"
    },
    animal_type_error: {
        [ZH_TW]: "請輸入動物種別",
        [EN]: "Please provide animal type"
    },
    animal_count_error:{
        [ZH_TW]: "請輸入動物數量",
        [EN]: "Please provide the number of animals"
    },
    animal_gender: {
        [ZH_TW]: "動物性別",
        [EN]: "Gender of Animal"
    },
    animal_gender_male: {
        [ZH_TW]: "公",
        [EN]: "Male"
    },
    animal_gender_female: {
        [ZH_TW]: "母",
        [EN]: "Female"
    },
    animal_gender_uncertainty: {
        [ZH_TW]: "不確定",
        [EN]: "Not Sure"
    },
    concerns: {
        [ZH_TW]: "投訴問題",
        [EN]: "Animal Welfare Complaints"
    },
    concerns_placeholder: {
        [ZH_TW]: "動物長期關籠/鍊繩/虐打/環境髒亂...",
        [EN]: "The environment is dirty"
    },
    time_of_sighting: {
        [ZH_TW]: "發現日期",
        [EN]: "Time of Sighting"
    },
    time_of_sighting_time_format_error: {
        [ZH_TW]: "時間格式錯誤",
        [EN]: "Invalid Date Format"
    },
    details: {
        [ZH_TW]: "情況詳述",
        [EN]: "Details"
    },
    details_hint: {
        [ZH_TW]: "請您盡可能客觀地詳述案件情況，提供 1. 動物健康狀態 2. 飼養環境 3. 飼養情況 4.飼主狀態，若有不足之處，調查員會在收到案件時與您聯繫。",
        [EN]: "Please give us the details: 1. Health status 2. Environment 3. Feeding status 4. Owner's status. If you can't provide enough information, we will reach you once we get this case."
    },
    animal_address_title: {
        [ZH_TW]: "動物所在位置",
        [EN]: "Location of Animal"
    },
    hint_for_animal_address:{
        [ZH_TW]: "未提供詳細地址將無法成案，如有困難請聯繫協會",
        [EN]: "If you can't provide address, please contact us"
    },
    animal_location_details: {
        [ZH_TW]: "地址",
        [EN]: "Address"
    },
    select_default_label: {
        [ZH_TW]: "請選擇",
        [EN]: "Please choose..."
    },
    animal_location_details_placeholder: {
        [ZH_TW]: "56弄81號五樓之三",
        [EN]: "56弄81號五樓之三"
    },
    animal_location_details_error: {
        [ZH_TW]: "請填寫地址或Google maps定位數字",
        [EN]: "Please give detailed address or GPS"
    },
    animal_location_city_list_choose: {
        [ZH_TW]: "請選擇縣市",
        [EN]: "Please choose city..."
    },
    animal_location_area_list_choose: {
        [ZH_TW]: "請選擇區域",
        [EN]: "Please choose area..."
    },
    animal_location_road_list_choose: {
        [ZH_TW]: "請選擇路道",
        [EN]: "Please choose road..."
    },
    animal_location_city_empty_error: {
        [ZH_TW]: "請選擇城市",
        [EN]: "Please choose city..."
    },
    animal_location_area_empty_error: {
        [ZH_TW]: "請選擇區域",
        [EN]: "Please choose area..."
    },
    animal_location_road_empty_error: {
        [ZH_TW]: "請選擇路道",
        [EN]: "Please choose road..."
    },
    animal_location_details_empty_error: {
        [ZH_TW]: "請提供地址",
        [EN]: "The given address is empty"
    },
    animal_location_notice: {
        [ZH_TW]: "一定要提供詳細地址，或是Google Maps定位數字，否則無法順利進行通報，如有問題請與協會聯繫，由調查員提供您蒐證技巧等相關資訊與建議。",
        [EN]: "Please provide an exact address or provide a GPS location or we will not accept the report. If you have any questions or difficulties, please contact us."
    },
    photo_notice: {
        [ZH_TW]: "請提供案件動物之照片、影片或錄音檔，沒有提供則無法入案；若因其他情況而無法取得相關證據，請與協會聯繫，由調查員提供您蒐證技巧、和飼主溝通之方法與後續協助等相關資訊與建議。  ",
        [EN]: "We can not accept a case if you do not provide relevant photos/videos/and or sound recordings. If you are unable to provide them, please contact us directly and our inspectors will assist you."
    },
    time_of_sighting_error: {
        [ZH_TW]: "請提供發現的時間",
        [EN]: "The given Time of Sighting is empty"
    },
    details_error: {
        [ZH_TW]: "請詳述案件情形",
        [EN]: "Please details field is empty"
    },
    upload_hint1: {
        [ZH_TW]: `・未提供案件動物之照片、影片或錄音檔將無法成案；如因特殊情況而無法取得相關證據，請與協會聯繫，調查員將會給予建議。`,
        [EN]: `[EN]・未提供案件動物之照片、影片或錄音檔將無法成案；如因特殊情況而無法取得相關證據，請與協會聯繫，調查員將會給予建議。`,
    },
    upload_hint2: {
        [ZH_TW]: `・容量限制20MB以下，副檔名須為jpg、jpeg、png、mp4、webm、ogv`,
        [EN]: `[EN]・容量限制20MB以下，副檔名須為jpg、jpeg、png、mp4、webm、ogv`,
    },
    upload_cloud_link_hint: {
        [ZH_TW]: `若檔案過大，請提供雲端連結`,
        [EN]: `Please provide link if the files are too large`,
    },
    upload_fail_size_too_big: {
        [ZH_TW]: "檔案超過20MB！請選擇較小的檔案或提供雲端連結！",
        [EN]: "The file size is too big.",
    },
    cloud_link: {
        [ZH_TW]: `雲端連結`,
        [EN]: `Cloud link`,
    },
    upload_fail: {
        [ZH_TW]: "上傳失敗",
        [EN]: "Failed.",
    },
    upload_btn_label: {
        [ZH_TW]: "上傳檔案",
        [EN]: "Upload"
    },
    web_upload_btn_label: {
        [ZH_TW]: "上傳檔案 or 將檔案拖曳進來",
        [EN]: "Click or drag here to upload files"
    },
    upload_error: {
        [ZH_TW]: "上傳失敗。注意檔案大小不可超過20MB",
        [EN]: "Upload failed, please note the maximum file size is 20MB"
    },
    upload_del_msg: {
        [ZH_TW]: "確定要刪除這檔案嗎？",
        [EN]: "Are you sure to delete this file?"
    },
    date_ok_msg: {
        [ZH_TW]: "確定",
        [EN]: "OK"
    },
    date_cancel_msg: {
        [ZH_TW]: "取消",
        [EN]: "Cancel"
    },
    date_today_msg: {
        [ZH_TW]: "今天",
        [EN]: "Today"
    },
    is_report_to_other_ngo: {
        [ZH_TW]: "您是否已通報政府主管機關/其他動保團體？",
        [EN]: "Have you previously reported this case to the government or other NGOs?"
    },
    is_personal_info: {
        [ZH_TW]: "為方便主管機關確認地點及資訊，請問您是否同意提供聯絡資訊給主管機關？（敬請放心，調查部門與主管機關將不會洩漏您的個人資訊）",
        [EN]: "If needed, will you allow us to provide your contact info to the government authorities should they need to be in touch with you? (Don’t worry we will not leak your personal information)"
    },
    reported_agency_hint:{
        [ZH_TW]: "請輸入政府主管機關或是動保團體",
        [EN]: "Animal Protection Office, Health Inspection Office, or other animal protection organizations."
    },
    case_files_error: {
        [ZH_TW]: "請上傳照片/影片。",
        [EN]: "Please provide the photos or videos for us.",
    },
    report_agency_error: {
        [ZH_TW]: "請提供您所通報的政府機關或動保團體",
        [EN]: "Please provide the government or other NGOs information",
    },
    submit_confirm_msg: {
        [ZH_TW]: "確定要送出此案件嗎？",
        [EN]: "Are you sure to submit this case?"
    },
    thankyou_success: {
        [ZH_TW]: "通報成功",
        [EN]: "Success"
    },
    go_to_spca_official_site: {
        [ZH_TW]: "前往官網",
        [EN]: "www.spca.org.tw"
    },
    case_num_label:{
        [ZH_TW]: "案件編號",
        [EN]: "Case number"
    },
    animal_species_label: {
        [ZH_TW]: "動物種類",
        [EN]: "Animal species"
    },
    animal_species_count: {
        [ZH_TW]: "數量",
        [EN]: "Count"
    },
    complaint_categories_label: {
        [ZH_TW]: "案件問題",
        [EN]: "Complaint category"
    },
    current_brief_label:{
        [ZH_TW]: "案件處理狀況",
        [EN]: "Current status"
    },
    labelDisplayedRows_label:{
        [ZH_TW]: ({ from, to, count })=>{
            return `資料從 ${from} 到 ${to} 筆 全部共 ${count} 筆資料`;
        },
        [EN]: ({ from, to, count })=>{
            return `${from}-${to} of ${count}`;
        }
    },
    thankyou_content_label:{
        [ZH_TW]: ({ case_num, animal_location, isWeb})=>{
            return <>
                <p style={{lineHeight: 2}}>
                    台灣防止虐待動物協會已接獲您
                    <Text test_id="case_num" style={{color: "#C70A0A"}}>({case_num})</Text>
                    <Text test_id="animal_location" style={{color: "#C70A0A"}}>({animal_location})</Text>
                    的動物保護案件舉報，
                    請您前往填寫的電子郵件信箱收取案件確認信。
                </p>
                <p style={{lineHeight: 2}}>
                    我們將在確認受理後由調查專員與您聯繫，查詢案件情況可前往
                    【<Link test_id="announcement_link" to={ANNOUNCEMENT_URL}>案件紀錄頁面</Link>】
                    利用您的案件編號查詢案件處理狀況。
                </p>
                {!isWeb && <p style={{lineHeight: 2}}>
                    若您未收到信件，請致電<Tel1 /> 或 <Tel2 />，與調查部門聯繫。
                </p>}
            </>
        },
        [EN]: ({ case_num, animal_location})=>{
            return <p style={{lineHeight: 1.5}}>
                ​Your report of animal abuse and/or neglect has been received. Case number
                <span test_id="case_num" style={{textDecoration: "underline", color: "red"}}>({case_num})</span>
                <span test_id="animal_location" style={{textDecoration: "underline", color: "red"}}>({animal_location})</span>.
                Please check your email for our confirmation letter.
                If you don't receive a confirmation email, please contact our inspectorate at <Tel1 /> or <Tel2 />.
                Once our inspectors have confirmed the information you’ve provided, we will be in touch with you.
                To find out updates about your case, please make a search via our webpage
                <span> <Link test_id="announcement_link" to={ANNOUNCEMENT_URL}>[Case Updates]</Link> </span>
                using your case number.
            </p>
        }
    },
    web_thankyou_content_label: {
        [ZH_TW]: "若您未收到信件請聯繫我們",
        [EN]: "Contact us"
    },
    line_id:{
        [ZH_TW]: "LINE ID",
        [EN]: "LINE ID"
    },
    investigation_log:{
        [ZH_TW]: "案件調查紀錄",
        [EN]: "Investigation Log"
    },
    search:{
        [ZH_TW]: "搜尋",
        [EN]: "Search"
    },
    video_button_title:{
        [ZH_TW]: "下載影片",
        [EN]: "Download video"
    },
    image_button_title:{
        [ZH_TW]: "下載圖片",
        [EN]: "Download image"
    },
    add_animal_detail:{
        [ZH_TW]: "新增動物",
        [EN]: "Add"
    },
    del_animal_detail:{
        [ZH_TW]: "刪除欄位",
        [EN]: "Del"
    },
    confirm_title_personal:{
        [ZH_TW]: "報案人資訊",
        [EN]: "Your Information"
    },
    confirm_title_animal_info:{
        [ZH_TW]: "動物資訊",
        [EN]: "Animal Information"
    },
    confirm_title_upload:{
        [ZH_TW]: "資料上傳",
        [EN]: "Photo & Videos"
    },
    confirm_name:{
        [ZH_TW]: "姓名",
        [EN]: "Name"
    },
    confirm_email:{
        [ZH_TW]: "信箱",
        [EN]: "Email"
    },
    confirm_phone:{
        [ZH_TW]: "電話",
        [EN]: "Telephone"
    },
    confirm_line_id:{
        [ZH_TW]: "LINE ID",
        [EN]: "LINE ID"
    },
    confirm_gender:{
        [ZH_TW]: "性別",
        [EN]: "Gender"
    },
    confirm_age:{
        [ZH_TW]: "年齡",
        [EN]: "Age"
    },
    confirm_source:{
        [ZH_TW]: "得知通報管道",
        [EN]: "Information source"
    },
    confirm_animal_species:{
        [ZH_TW]: "動物種別",
        [EN]: "Type of Animal"
    },
    confirm_animal_count:{
        [ZH_TW]: "動物數量",
        [EN]: "The number of animal"
    },
    confirm_complaint_category:{
        [ZH_TW]: "案件問題",
        [EN]: "Complaint category"
    },
    confirm_animal_address:{
        [ZH_TW]: "動物所在位置",
        [EN]: "Location of Animal"
    },
    confirm_concerns:{
        [ZH_TW]: "投訴問題",
        [EN]: "Animal Welfare Complaints"
    },
    confirm_time_of_sighting:{
        [ZH_TW]: "發現日期",
        [EN]: "Time of Sighting"
    },
    confirm_details:{
        [ZH_TW]: "情況詳述",
        [EN]: "Details"
    },
    confirm_is_report_to_other_ngo:{
        [ZH_TW]: "是否已通報政府主管機關/其他動保團體",
        [EN]: "Have you previously reported this case to the government or other NGOs?"
    },
    confirm_report_agency:{
        [ZH_TW]: "主管機關",
        [EN]: "Government or other NGOs"
    },
    confirm_is_personal_info:{
        [ZH_TW]: "為方便主管機關確認地點及資訊，請問您是否同意提供聯絡資訊給主管機關?(調查部門與主管機關將不會洩漏您的個人資訊，敬請放心)",
        [EN]: "If needed, will you allow us to provide your contact info to the government authorities should they need to be in touch with you? (Don’t worry we will not leak your personal information)"
    },
    yes:{
        [ZH_TW]: "是",
        [EN]: "Yes"
    },
    no:{
        [ZH_TW]: "否",
        [EN]: "No"
    },
    report_system: {
        [ZH_TW]: "通報系統",
        [EN]: "Report system"
    },
    error_can_not_find_data:{
        [ZH_TW]: "找不到資料",
        [EN]: "Can't find data"
    },
    reach_max_number_of_file:{
        [ZH_TW]: `抱歉，目前不允許上傳超過${MAX_NUMBER_OF_UPLOAD_FILES}個檔案`,
        [EN]: `Sorry that we only allow upload ${MAX_NUMBER_OF_UPLOAD_FILES} files`,
    },
    error_not_this_page:{
        [ZH_TW]: "沒有此頁面",
        [EN]: "404 Not Found"
    }
};

export function get_msg_txt(lang = DEFAULT_LANG, msg = {}, params={}) {
    switch (typeof msg[lang]){
        case "object": {
            const value = msg[lang];
            const defaultValue = _.isArray(value) ? [] : {};
            return value || defaultValue;
        }
        case "string": {
            return msg[lang] || "";
        }
        case "function":{
            return msg[lang](params);
        }
        default:{
            return "";
        }
    }
}

export default msg;
