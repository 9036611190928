import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {useDispatch} from "react-redux";
import NoticeRow from "./NoticeRow";
import {ON_NEXT_STEP} from "../../../../actions/types";
import Button from "../../../../components/Home/shared/Button/Button";
import WhiteBoard from "../../../../components/WhiteBoard";
import PrevStepButton from "../../../../components/Home/PrevStepButton";

const NoticeWhiteBoard = (({className, hasPrev=false, buttonText="", rows=[]})=>{
    const dispatch = useDispatch();

    return <WhiteBoard className={className}>
        {rows.map((data)=><NoticeRow key={_.uniqueId("NoticeWhiteBoard")} text={data} />)}

        {hasPrev && <PrevStepButton />}
        <Button onClick={()=>{
            dispatch({ type: ON_NEXT_STEP });
        }}>{buttonText}</Button>
    </WhiteBoard>
});

export default NoticeWhiteBoard;

NoticeWhiteBoard.propTypes = {
    className: PropTypes.string,
    buttonText: PropTypes.string,
};
