import React, {Fragment} from "react";
import styled from "styled-components";
import Row from "../../../../../WholeRow";
import msg, {get_msg_txt} from "../../../../../../../consts/msg";
import _Select from "../../../../../shared/SelectRow/SelectCaseOneColumn";
import {DEFAULT_CITY_VALUE} from "../CitySelect";
import {DEFAULT_AREA_VALUE} from "../AreaSelect";
import useAreaAjax from "../AreaSelect/useAreaAjax";
import useLang from "../../../../../../../hooks/useLang";
import useCaseOne from "../../../../../../../hooks/useCaseOne";

export const DEFAULT_ROAD_VALUE = "DEFAULT_ROAD_VALUE";

const Select = styled(_Select)`
  select{
    width: 100%;
  }
`;

const RoadSelect = () => {
    const lang = useLang();
    const {animal_location_city, animal_location_area} = useCaseOne();
    const {isLoading, data, isError} = useAreaAjax({animal_location_city});

    if(isLoading || isError){
        return <Fragment />
    }

    const area_list = data.data;
    const isCitySelected = animal_location_city !== DEFAULT_CITY_VALUE;
    const isAreaSelected = animal_location_area !== DEFAULT_AREA_VALUE;
    const roadList = area_list[animal_location_area] || [];

    if(!isCitySelected || !isAreaSelected) return <Fragment />

    const options = [{label: get_msg_txt(lang, msg.animal_location_road_list_choose), value: DEFAULT_ROAD_VALUE}].concat(roadList.map(value=>{
        return {value, label: value};
    }));

    return <Row>
        <Select
            columnName="animal_location_road"
            options={options}
        />
    </Row>
};

export default RoadSelect;
