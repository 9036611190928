import React from "react";
import styled from "styled-components";
import {ImCross} from "react-icons/im";

const Container = styled.div`
  display: flex;
  color: #CB1212;
`;

const Icon = styled(ImCross)`
  font-size: 1.125rem;
  margin-right: 7px;
`

const FailedMessage = (({children}) => {
    return <Container>
        <Icon />
        {children}
    </Container>
});

export default FailedMessage;

FailedMessage.propTypes = {
};
