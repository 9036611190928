import React from 'react';
import styled, {css} from "styled-components";
import useIsWeb from "../../../../hooks/useIsWeb";

const Container = styled.div`
  display: grid;
  grid-template-columns: 6.25rem 1fr;
  
  ${props => props.isWeb && css`
      grid-template-columns: 7.25rem 25rem;
  `};
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: left;
  align-items: center;
`;

const Left = styled(FlexBox)`
  font-size: 1.125rem;
  color: #434242;
  
  ${props=>props.isWeb && css`
    font-size: 1rem;
    color: #434242;
  `};
`;

const KeyValueField = (({className, left: Key, right: Value}) => {
    const isWeb = useIsWeb();

    return <Container className={className} isWeb={isWeb}>
        <Left isWeb={isWeb}>
            {Key}
        </Left>
        <FlexBox>
            {Value}
        </FlexBox>
    </Container>
});

export default KeyValueField;

KeyValueField.propTypes = {
};
