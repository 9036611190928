import React from "react";
import styled from "styled-components";
import Bottom from "./Bottom";
import _NoticeWhiteBoard from "../../../../layouts/NoticeLayout/mobile/NoticeWhiteBoard";
import NoticeHeader from "../../../../layouts/NoticeLayout/mobile/NoticeWhiteBoard/NoticeHeader";
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";
import useScrollToTop from "../../../../hooks/useScrollToTop";

const Container = styled.div`
  width: 18.875rem;
  margin-top: 2rem;
`;

const NoticeWhiteBoard = styled(_NoticeWhiteBoard)`
  min-height: 35rem;
`

const MobileSecondNotice = (()=>{
    useScrollToTop();
    const lang = useLang();
    const rows = get_msg_txt(lang, msg.notice_contents2);

    return <Container>
        <NoticeHeader />
        <NoticeWhiteBoard rows={rows} buttonText={get_msg_txt(lang, msg.start_report)} hasPrev={true} />
        <Bottom />
    </Container>
});

export default MobileSecondNotice;

MobileSecondNotice.propTypes = {
};
