import React, {memo} from 'react';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#125e9f"
        }
    },
});

export default memo(({children})=>{
    return <ThemeProvider theme={theme}>
        {children}
    </ThemeProvider>
});
