import React from "react";
import styled from "styled-components";
import {RiGlobalLine} from 'react-icons/ri';
import {useDispatch} from "react-redux";
import Popover from "@material-ui/core/Popover";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Arrow from "../../layouts/NoticeLayout/mobile/NoticeWhiteBoard/Arrow";
import useLang from "../../hooks/useLang";
import msg, {get_msg_txt} from "../../consts/msg";
import {EN, ZH_TW} from "../../consts/lang";
import {APP_CHANGE_LANG} from "../../actions/types";
import useIsWeb from "../../hooks/useIsWeb";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
    width: ${props => props.isWeb ? "5.5rem": "3.75rem"};
    height: 1.5rem;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    user-select: none;
    cursor: pointer;
`;

const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GlobalIcon = styled(RiGlobalLine)`
    color: #716E6E;
    font-weight: bold;
    width: 1.125rem;
    height: 1.125rem;
`

const Text = styled.span`
    color: #707070;
    font-size: 14px;
`;

const GlobalSelect = (({className})=>{
    const isWeb = useIsWeb();
    const dispatch = useDispatch();
    const lang = useLang();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const open = Boolean(anchorEl);
    const id = open ? 'global-select' : undefined;

    const onSelectLang = (lang) => {
        setAnchorEl(null);
        dispatch({
            type: APP_CHANGE_LANG,
            payload: lang
        });
    };
    const handleClose = () => setAnchorEl(null);

    const msgValue = isWeb ? msg.lang_web : msg.lang;
    const text = get_msg_txt(lang, msgValue);
    const zhText = get_msg_txt(ZH_TW, msgValue);
    const enText = get_msg_txt(EN, msgValue);

    return <Container className={className}>
        <Wrapper isWeb={isWeb} onClick={event=>setAnchorEl(event.currentTarget)}>
            <FlexBox>
                <GlobalIcon />
            </FlexBox>
            <FlexBox>
                <Text>{text}</Text>
            </FlexBox>
            <FlexBox>
                <Arrow />
            </FlexBox>
        </Wrapper>
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={()=>onSelectLang(ZH_TW)} selected={lang===ZH_TW}>{zhText}</MenuItem>
                <MenuItem onClick={()=>onSelectLang(EN)} selected={lang===EN}>{enText}</MenuItem>
            </Menu>
        </Popover>
    </Container>
});

export default GlobalSelect;

GlobalSelect.propTypes = {
};
