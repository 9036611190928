import React from "react";
import styled from "styled-components";

const Container = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  position: relative;
`;

const Input = styled.input`
  top: 0;
  left: 0;
  width: 100%;
  cursor: inherit;
  height: 100%;
  margin: 0;
  opacity: 0;
  padding: 0;
  z-index: 1;
  position: absolute;
`;

const Div = styled.div`
    display: flex;
    position: relative;
`;

const Circle = styled.svg`
  fill: #707070;
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
`

const InnerCircle = styled.svg`
  transform: scale(${props=>props.checked ? 1: 0});
  transition: transform 150ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
  left: 0;
  position: absolute;
`;

const RoundedButton = ({className, checked=false, onChange}) => {
    return <Container className={className} onClick={onChange}>
        <Input type="radio" />
        <Div>
            <Circle focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z" />
            </Circle>
            <InnerCircle focusable="false" viewBox="0 0 24 24" aria-hidden="true" checked={checked}>
                <path d="M8.465 8.465C9.37 7.56 10.62 7 12 7C14.76 7 17 9.24 17 12C17 13.38 16.44 14.63 15.535 15.535C14.63 16.44 13.38 17 12 17C9.24 17 7 14.76 7 12C7 10.62 7.56 9.37 8.465 8.465Z"/>
            </InnerCircle>
        </Div>
    </Container>
};

export default RoundedButton;
