import React from 'react';
import PropTypes from "prop-types";
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import RadioButtons from "../RadioButtons";
import {ON_CASE_FIELD_CHANGE} from "../../../../../actions/types";

const Container = styled(RadioButtons)`
  display: flex;
  justify-content: space-between;
`;

const IsReportedField = (({className}) => {
    const dispatch = useDispatch();
    const checked = useSelector(state=>state.case_one.is_reported);

    return <Container className={className} checked={checked} onChange={value=>{
        dispatch({
            type: ON_CASE_FIELD_CHANGE,
            columnName: "is_reported",
            payload: value
        });
    }}>

    </Container>
});

export default IsReportedField;

IsReportedField.propTypes = {
    className: PropTypes.string
};
