import React from "react";
import ErrorMsg from '../components/ErrorMsg';
import msg, {get_msg_txt} from "../consts/msg";
import useLang from "../hooks/useLang";
import {NoticeLayout} from "../layouts";

const Error_Route = (()=>{
    const lang = useLang();

    return <NoticeLayout>
        <ErrorMsg>{get_msg_txt(lang, msg.error_not_this_page)}</ErrorMsg>
    </NoticeLayout>
});


export default Error_Route;
