import React from 'react';
import useIsWeb from "../../../hooks/useIsWeb";
import WebAnimalInformation from "./web";
import MobileAnimalInformation from "./mobile";

const AnimalInformation = (() => {
    const isWeb = useIsWeb();
    if(isWeb){
        return <WebAnimalInformation />
    }
    else{
        return <MobileAnimalInformation />
    }
});

export default AnimalInformation;

AnimalInformation.propTypes = {
};
