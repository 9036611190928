import React from 'react';
import NameField from "./NameField";
import EmailField from "./EmailField";
import PhoneField from "./PhoneField";
import LineIDField from "./LineIDField";
import GenderField from "./GenderField";
import AgeField from "./AgeField";
import SourceField from "./SourceField";
import AccordionSummary from "../../../../layouts/StepLayout/mobile/AccordionSummary";
import AccordionContent from "../../../../layouts/StepLayout/mobile/AccordionContent";
import {YOUR_INFORMATION} from "../../../../consts/step";
import msg, {get_msg_txt} from "../../../../consts/msg";
import useLang from "../../../../hooks/useLang";
import useStep from "../../../../hooks/useStep";
import useHasError from "../shared/useHasError";
import SubmitBoxRow from "../../shared/SubmitBoxRow";
import useScrollToTop from "../../../../hooks/useScrollToTop";

const MobileYourInformation = (() => {
    useScrollToTop();
    const lang = useLang();
    const step = useStep();
    const hasError = useHasError();

    const open = step === YOUR_INFORMATION;

    return <>
        <AccordionSummary title={get_msg_txt(lang, msg.your_information)} isRedCircle={hasError} open={open} />
        <AccordionContent open={open}>
            {open && <>
                <NameField />
                <EmailField />
                <PhoneField />
                <LineIDField />
                <GenderField />
                <AgeField />
                <SourceField />
                <SubmitBoxRow disabled={hasError} text={get_msg_txt(lang, msg.next_step)} />
            </>}
        </AccordionContent>
    </>
});

export default MobileYourInformation;

MobileYourInformation.propTypes = {
};
