import React from "react";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";
import NoticeRow from "../../../../layouts/NoticeLayout/mobile/NoticeWhiteBoard/NoticeRow";
import {ON_NEXT_STEP} from "../../../../actions/types";
import Button from "../../shared/Button";
import BottomNotice from "./BottomNotice";
import PrevStepButton from "../../PrevStepButton";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2.625rem;
`;

const Box = styled.div`
  width: 31.25rem;
  display: flex;
  flex-direction: column;
  
  > * {
    margin-bottom: 3.125rem;
  }
`;

const BlueBar = styled.div`
  height: 3.125rem;
  background-color: #6C87C6;
  color: white;
  font-size: 1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const WebNoticeRow = styled(NoticeRow)`
  font-size: 1.125rem;
  grid-template-columns: 1.125rem auto;
`

const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const WebSecondNotice = (()=>{
    const dispatch = useDispatch();
    const lang = useLang();
    const rows = get_msg_txt(lang, msg.notice_contents2);

    return <Container>
        <Box>
            <BlueBar>{get_msg_txt(lang, msg.notice)}</BlueBar>
            {rows.map((data)=><WebNoticeRow key={_.uniqueId("NoticeWhiteBoard")} text={data} />)}

            <BottomNotice />
            <ButtonBox>
                <PrevStepButton />
                <Button onClick={()=>{
                    dispatch({ type: ON_NEXT_STEP });
                }}>{get_msg_txt(lang, msg.start_report)}</Button>
            </ButtonBox>
        </Box>
    </Container>
});

export default WebSecondNotice;

WebSecondNotice.propTypes = {
};
