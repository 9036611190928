import _ from "lodash";
import {shallowEqual, useSelector} from "react-redux";
import {DEFAULT_CITY_VALUE} from "./PlaceRow/shared/CitySelect";
import {DEFAULT_AREA_VALUE} from "./PlaceRow/shared/AreaSelect";
import {DEFAULT_ROAD_VALUE} from "./PlaceRow/shared/RoadSelect";
import {DEFAULT_SELECT_VALUE} from "../../../../config";

const hasAnimalDetailsError = (animal_details=[]) => {
    return animal_details.length===0 || !!animal_details.find(({animal_species, complaint_category, count})=>{
        return animal_species===DEFAULT_SELECT_VALUE || count === 0 || complaint_category.length===0;
    });
};

const hasError = ({animal_location_city, animal_location_area, animal_location_road, animal_location_details, animal_details, details})=>{
    return hasAnimalDetailsError(animal_details) ||
        animal_location_city === DEFAULT_CITY_VALUE ||
        animal_location_area === DEFAULT_AREA_VALUE ||
        animal_location_road === DEFAULT_ROAD_VALUE ||
        _.isEmpty(animal_location_details) ||
        _.isEmpty(details);
}

const useHasError = () => {
    const {animal_location_city, animal_location_area, animal_location_road, animal_location_details, animal_details, details} = useSelector(({case_one}) => {
        const {animal_location_city, animal_location_area, animal_location_road, animal_location_details, animal_details, details} = case_one;

        return {
            animal_location_city,
            animal_location_area,
            animal_location_road,
            animal_location_details,
            animal_details,
            details
        };
    }, shallowEqual);

    return hasError({animal_location_city, animal_location_area, animal_location_road, animal_location_details, animal_details, details});
};

export default useHasError;