import _ from 'lodash';
import React, {useState} from 'react';
import {useSelector} from "react-redux";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import LabelTextField from "../../../shared/LabelTextField";

export function getError(lang, value=""){
    return _.isEmpty(value)? get_msg_txt(lang, msg.name_error) : "";
}

const NameField = (() => {
    const [dirty, onDirty] = useState(false);
    const columnName = "whistleblower_name";
    const {lang, value} = useSelector(({app, case_one}) => {
        return {lang: app.lang, value: case_one[columnName]};
    });

    return <LabelTextField
        columnName={columnName}
        isRequired={true}
        placeholder={get_msg_txt(lang, msg.name_placeholder)}
        label={get_msg_txt(lang, msg.name)}
        error={!dirty? "" :getError(lang, value)}
        onDirty={()=>onDirty(true)}
    />
});

export default NameField;

NameField.propTypes = {
};
