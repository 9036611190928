import React from 'react';
import styled from "styled-components";
import SightingRow from "../shared/SightingRow";
import PlaceRow from "../shared/PlaceRow";
import AccordionSummary from "../../../../layouts/StepLayout/mobile/AccordionSummary";
import AccordionContent from "../../../../layouts/StepLayout/mobile/AccordionContent";
import AnimalDetails from "../shared/AnimalDetails/mobile";
import DetailsRow from "../shared/DetailsRow";
import useHasError from "../shared/useHasError";
import {INFORMATION_OF_ANIMAL} from "../../../../consts/step";
import msg, {get_msg_txt} from "../../../../consts/msg";
import PrevStepButton from "../../PrevStepButton";
import NextStepButton from "../shared/NextStepButton";
import useStep from "../../../../hooks/useStep";
import useLang from "../../../../hooks/useLang";
import useScrollToTop from "../../../../hooks/useScrollToTop";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
`;

const NextStepRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const MobileAnimalInformation = (() => {
    useScrollToTop();
    const step = useStep();
    const lang = useLang();
    const open = step === INFORMATION_OF_ANIMAL;
    const hasError = useHasError();

    return <>
        <AccordionSummary isRedCircle={hasError} title={get_msg_txt(lang, msg.information_and_animal)} open={open} />
        <AccordionContent open={open}>
            {open && <Container>
                <SightingRow />
                <PlaceRow />
                <AnimalDetails />
                <DetailsRow />
                <NextStepRow>
                    <PrevStepButton />
                    <NextStepButton />
                </NextStepRow>
            </Container>}
        </AccordionContent>
    </>
});

export default MobileAnimalInformation;

MobileAnimalInformation.propTypes = {
};
