import React, {memo} from "react";
import PropTypes from "prop-types";
import ErrorMsg from "../ErrorMsg";
import msg, {get_msg_txt} from "../../consts/msg";
import {DEFAULT_LANG} from "../../config";

const NotFound = memo(({lang=DEFAULT_LANG})=>{
    return <ErrorMsg>{get_msg_txt(lang, msg.error_can_not_find_data)}</ErrorMsg>
});

export default NotFound;

NotFound.propTypes = {
    lang: PropTypes.string
};
