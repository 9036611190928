import React from "react";
import appendQuery from "append-query";
import utils from '../utils/utils';
import ThankYou from '../components/ThankYou';
import useIsWeb from "../hooks/useIsWeb";
import {NoticeLayout, StepLayout} from "../layouts";

export const route_path = "/thankyou";

export function redirect_route(params = {}) {
    const url = appendQuery(route_path, params);
    utils.redirect_route(url);
}

const ThankYou_Route = (() => {
    const isWeb = useIsWeb();
    if(isWeb){
        return <StepLayout>
            <ThankYou />
        </StepLayout>
    }
    else{
        return <NoticeLayout>
            <ThankYou />
        </NoticeLayout>
    }
});

ThankYou_Route.propTypes = {
};

export default ThankYou_Route;