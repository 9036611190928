import React, {memo} from 'react';
import PropTypes from "prop-types";
import LinkBase from "../LinkBase";

const Tel = memo(({number="", test_id=""}) => {
    return <LinkBase test_id={test_id} title={number} href={`tel:${number}`}>{number}</LinkBase>
});

Tel.propTypes = {
    number: PropTypes.string,
    test_id: PropTypes.string
};

export default Tel;
