import React from 'react';
import Select from "../../../shared/SelectRow/Select";
import useComplaintCategoryList from "../../../../../hooks/useComplaintCategoryList";

const ComplaintCategorySelect = (({value, onChange}) => {
    const {isLoading, data} = useComplaintCategoryList();

    if(isLoading) return <div>Loading...</div>;

    const complaint_categories = data.data;
    const options = complaint_categories.map(({id, name}) => {
        return {value: id, label: name};
    });

    return <Select
        multiple
        options={options}
        value={value}
        onChange={onChange}
    />
});

export default ComplaintCategorySelect;

ComplaintCategorySelect.propTypes = {
};
