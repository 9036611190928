import React from "react";
import msg, {get_msg_txt} from "../../../../consts/msg";
import useLang from "../../../../hooks/useLang";
import RedStar from "../../RedStar";
import styled from "styled-components";
import SharedUploadButton from "../shared/UploadButton";
import SharedCloudLinkField from "../shared/CloudLinkField";
import IsReportedField from "../shared/IsReportedField";
import useIsReported from "../shared/useIsReported";
import ReportAgencyField from "../shared/ReportAgencyField";
import IsPersonalInfo from "../shared/IsPersonalInfo";
import CaseFiles from "../shared/CaseFiles";
import useIsNextDisabled from "../shared/useIsNextDisabled";
import SubmitBoxRow from "../../shared/SubmitBoxRow";

const GAP = "1rem";
const GAP_BIG = "2rem";

const Container = styled.div`
  width: 33.125rem;
  color: #434242;
  font-size: 1rem;
`

const RedText = styled.div`
  font-size: 0.6875rem;
  color: #CB1212;
  margin-top: ${GAP};
  margin-bottom: ${GAP};
`

const UploadButton = styled(SharedUploadButton)`
  margin-top: ${GAP_BIG};
`;

const CloudLinkField = styled(SharedCloudLinkField)`
  margin-top: ${GAP};
`;

const Box = styled.div`
  margin-top: ${GAP_BIG};
`;

const WebPhotosAndVideos = () => {
    const lang = useLang();
    const is_reported = useIsReported();
    const isNextDisabled = useIsNextDisabled();

    return <Container>
        {get_msg_txt(lang, msg.photos_and_videos_title)}<RedStar />
        <RedText>{get_msg_txt(lang, msg.upload_hint1)}</RedText>
        <RedText>{get_msg_txt(lang, msg.upload_hint2)}</RedText>
        <UploadButton />
        <CaseFiles />
        <Box>
            {get_msg_txt(lang, msg.upload_cloud_link_hint)}
            <CloudLinkField />
        </Box>
        <Box>
            {get_msg_txt(lang, msg.is_report_to_other_ngo)}
            <IsReportedField />
            {is_reported && <div><ReportAgencyField /></div>}
        </Box>
        <Box>
            {get_msg_txt(lang, msg.is_personal_info)}
            <IsPersonalInfo />
        </Box>
        <SubmitBoxRow text={get_msg_txt(lang, msg.submit_btn)} disabled={isNextDisabled} />
    </Container>
};

export default WebPhotosAndVideos;