import React from 'react';
import styled from "styled-components";

const Container = styled.span`
  color: #ED7B7B;
`;

const RedStar = (() => {
    return <Container>*</Container>
});

export default RedStar;

RedStar.propTypes = {
};
