import utils from "../utils/utils";
import {DEFAULT_PAGE} from "../config";

function get_page_by_cur_filters({filters={}}){
    return utils.get_value_by_cur_filters({filters, field: "page", default_value: DEFAULT_PAGE});
}

const usePage = ()=>{
    const filters = utils.get_filters(window.location.search)
    return get_page_by_cur_filters({filters});
}

export default usePage;