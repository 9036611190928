import React from 'react';
import Select from "./SelectCaseOneColumn";

import ColumnRow from "../ColumnRow";

const SelectRow = (({columnName, isRequired, label, options, error, onDirty=()=>{}}) => {
    return <ColumnRow label={label} error={error} isRequired={isRequired}>
        <Select
            columnName={columnName}
            options={options}
            onDirty={onDirty}
        />
    </ColumnRow>
});

export default SelectRow;

SelectRow.propTypes = {
};
