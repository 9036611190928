import _ from "lodash";
import React, {Fragment} from "react";
import styled from "styled-components";
import useAnimalSpeciesList from "../../hooks/useAnimalSpeciesList";
import useComplaintCategoryList from "../../hooks/useComplaintCategoryList";

const Container = styled.div`
  display: grid;
  grid-template-columns: 8.75rem 15.3125rem;
  
  :not(:last-child):after {
    content: "";
    width: 24.0625rem;
    height: 1px;
    background-color: #D9D9D9;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
`;

const CaseAnimalDetailRow = ({animal_details=[]}) => {
    const {isLoading: isAnimalSpeciesLoading, data: animalSpeciesData} = useAnimalSpeciesList();
    const {isLoading: isComplaintCategoryLoading, data: complaintCategoryData} = useComplaintCategoryList();

    if(isAnimalSpeciesLoading || isComplaintCategoryLoading){
        return <Fragment />
    }

    const animalSpeciesMap = _.mapKeys(animalSpeciesData.data, obj => obj.id);
    const complaintCategoryMap = _.mapKeys(complaintCategoryData.data, obj => obj.id);

    return <>
        {animal_details.filter(e=>e.animal_species!==null).map(({id, animal_species, count, complaint_category=[]})=>{
            return <Container key={id}>
                <div>
                    {animalSpeciesMap[animal_species]?.name}*{count}
                </div>
                <div>
                    {complaint_category.map(id=>{
                        return <div key={id}>
                            {complaintCategoryMap[id]?.name}
                        </div>
                    })}
                </div>
            </Container>
        })}
    </>
};

export default CaseAnimalDetailRow;
