import React from "react";
import {useDispatch} from "react-redux";
import styled from "styled-components";
import Button from "../../../shared/Button";
import {ON_STEP_CHANGE} from "../../../../../actions/types";
import {YOUR_INFORMATION} from "../../../../../consts/step";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import {onSubmit} from "../../../../../actions/case";
import useCaseOne from "../../../../../hooks/useCaseOne";
import useLang from "../../../../../hooks/useLang";

const BottomContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  flex-direction: column;
`;

const SubmitButtonBlock = () => {
    const lang = useLang();
    const caseOne = useCaseOne();
    const dispatch = useDispatch();

    return <BottomContainer>
        <Button onClick={()=>{
            dispatch({ type: ON_STEP_CHANGE, payload: YOUR_INFORMATION});
        }}>{get_msg_txt(lang, msg.edit_step)}</Button>

        <Button onClick={()=>{
            dispatch(onSubmit({lang, case_one: caseOne}));
        }}>{get_msg_txt(lang, msg.confirmed_btn)}</Button>
    </BottomContainer>
};

export default SubmitButtonBlock;