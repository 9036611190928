import React from 'react';
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
`;

const FirstDiv = styled.div`
  &:after{
    content: ":";
    margin-left: 5px;
    margin-right: 5px;
  }
`;

const ContentRow = (({left, right}) => {
    return <Container>
        <FirstDiv>{left}</FirstDiv>
        <div>{right}</div>
    </Container>
});

export default ContentRow;

ContentRow.propTypes = {
};
