import React from "react";
import ImageFilePreview from "../ImageFilePreview";
import VideoButton from "../VideoButton";
import {isImageExt} from "../UploadButton";
import useGetFileExt from "../../../../../hooks/useGetFileExt";
import ImageButton from "../ImageButton";

const FilePreview = ({src, file, isUploaded=false}) => {
    const {ext, isLoading} = useGetFileExt(file);

    if(isLoading){
        return <ImageButton />
    }
    else{
        const isImage = isImageExt(ext);

        return <>
            {isImage ? <ImageFilePreview isUploaded={isUploaded} src={src} file={file} /> : <VideoButton />}
        </>
    }
};

export default FilePreview;