import msg, {get_msg_txt} from "../../../../consts/msg";
import getYesNoLabel from "./getYesNoLabel";

const getReportText = ({lang, is_reported, report_agency}) => {
    let reportText = `${get_msg_txt(lang, msg.confirm_is_report_to_other_ngo)} : ${getYesNoLabel({lang, value: is_reported})}`;
    if(is_reported){
        reportText = reportText.concat(`。${report_agency}`);
    }

    return reportText
};

export default getReportText;