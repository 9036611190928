import React from "react";
import styled from "styled-components";
import msg, {get_msg_txt} from "../../../../consts/msg";
import useLang from "../../../../hooks/useLang";
import TelIcon from "../../../TelIcon";
import EmailIcon from "../../../EmailIcon";
import Tel1 from "../../shared/Tel1";
import Tel2 from "../../shared/Tel2";
import EmailText from "../../../EmailText";

const Container = styled.div`
  background-color: #FAF7F7;
  padding: 0.875rem;
  height: 5rem;
  border-radius: 12px;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  
  display: grid;
  grid-template-columns: 16.5625rem 1fr;
  grid-template-areas: 
    "title tel"
    ".     email";
`;

const Title = styled.div`
  grid-area: title;
  color: #000000;
  font-size: 1.125rem;
  font-weight: bold;
  
  display: flex;
  justify-content: start;
  align-items: center;
`;

const Cell = styled.div`
  display: grid;
  grid-template-columns: 60px auto;
`;

const Tel = styled(Cell)`
  grid-area: tel;
`;

const Box = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

const Email = styled(Cell)`
  grid-area: email;
`;

const ContactUs = () => {
    const lang = useLang();

    return <Container>
        <Title>
            {get_msg_txt(lang, msg.web_thankyou_content_label)}
        </Title>
        <Tel>
            <TelIcon />
            <Box>
                <Tel1 />
                &nbsp;or&nbsp;
                <Tel2 />
            </Box>
        </Tel>
        <Email>
            <EmailIcon />
            <Box><EmailText /></Box>
        </Email>
    </Container>
};

export default ContactUs;