import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Loading from "../../../components/Loading";
import bg from '../../shared/bg.svg';
import WebHeader from "./WebHeader";
import useIsLoaded from "../shared/useIsLoaded";
import WebFooter from "./WebFooter";
import useIsFooter from "./useIsFooter";
import useScrollToTop from "../../../hooks/useScrollToTop";

const Container = styled.div`
  min-height: 100%;
  
  background-image: url("${bg}");
  
  font-size: 16px;
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const WhiteBoard = styled.div`
    background-color: white;
    border-radius: 25px;
    min-height: 37.5rem; /*600*/
    width: 44rem;
    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
`;

const Box = styled.div`
  width: 53%;
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const WebNoticeLayout = (({className, children})=>{
    useScrollToTop();
    const isLoaded = useIsLoaded();
    const isFooter = useIsFooter();

    if (!isLoaded) {
        return <Loading/>
    }
    else {
        //#root
        return <Container className={className}>
            <Box>
                <WebHeader />
                <WhiteBoard>
                    {children}
                    {isFooter && <WebFooter />}
                </WhiteBoard>
            </Box>
        </Container>
    }
});

export default WebNoticeLayout;

WebNoticeLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.any,
};
