import styled from "styled-components";

const TextField = styled.input`
  width: 100%;
  height: 40px;
  padding-left: 5px;
  padding-right: 5px;
  font: inherit;
  border: solid .5px #AFAEAE;
  appearance: none;
  
  &:focus{
    outline: none;
    border: solid 2px #1976D2;
  }
`;

export default TextField;

TextField.propTypes = {
};
