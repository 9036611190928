import React from "react";
import redCircle from "./red_circle.svg";
import Circle from "../Circle";

const RedFullCircle = (()=>{
    return <Circle src={redCircle}/>;
});

export default RedFullCircle;

RedFullCircle.propTypes = {
};
