import React from "react";
import useIsWeb from "../../hooks/useIsWeb";
import MobileThankYou from "./mobile";
import WebThankYou from "./web";

const ThankYou = ()=>{
    const isWeb = useIsWeb();
    if(isWeb){
        return <WebThankYou />
    }
    else{
        return <MobileThankYou />
    }
};

ThankYou.propTypes = {
};

export default ThankYou;
