import React from "react";
import redCircle from "./red_circle.svg";
import Circle from "../Circle";

const RedCircle = (({className})=>{
    return <Circle className={className} src={redCircle}/>;
});

export default RedCircle;

RedCircle.propTypes = {
};
