import React from 'react';
import styled, {css} from 'styled-components';
import useIsWeb from "../../../../../../../hooks/useIsWeb";

const Container = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  margin-top: 1.25rem;
  
  ${props=>props.isWeb && css`
    justify-content: start;
  `};
`;

const ButtonRowInner = styled.div`
  width: 18.75rem;
`;

const ButtonRow = (({children, className}) => {
    const isWeb = useIsWeb();

    return <Container className={className} isWeb={isWeb}>
        <ButtonRowInner>
            {children}
        </ButtonRowInner>
    </Container>
});

export default ButtonRow;

ButtonRow.propTypes = {

};
