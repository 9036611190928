import React from "react";
import {useDispatch} from "react-redux";
import {ON_PREV_STEP} from "../../../actions/types";
import Button from "../shared/Button";
import useLang from "../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../consts/msg";

const PrevStepButton = ({disabled=false}) => {
    const lang = useLang();
    const dispatch = useDispatch();

    return <Button disabled={disabled} onClick={()=>{
        dispatch({ type: ON_PREV_STEP });
    }}>{get_msg_txt(lang, msg.pre_step)}</Button>
};

export default PrevStepButton;
