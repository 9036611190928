import React from "react";
import useIsWeb from "../../../../../hooks/useIsWeb";
import MobilePlaceRow from "./mobile";
import WebPlaceRow from "./web";

const PlaceRow = () => {
    const isWeb = useIsWeb();

    if (isWeb) {
        return <WebPlaceRow />
    }
    else{
        return <MobilePlaceRow />
    }
};

export default PlaceRow;
