import React, {useState} from 'react';
import {useSelector} from "react-redux";
import SelectRow from "../../../shared/SelectRow";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import {DEFAULT_SELECT_VALUE} from "../../../../../config";

const columnName = "whistleblower_age";
const ageOptions = [
    {value: "0-17", label: "0-17"},
    {value: "18-24", label: "18-24"},
    {value: "25-34", label: "25-34"},
    {value: "35-44", label: "35-44"},
    {value: "45-54", label: "45-54"},
    {value: "54-64", label: "44-64"},
    {value: "65+", label: "65+"},
];

const AgeField = (() => {
    const [dirty, onDirty] = useState(false);
    const {lang, value} = useSelector(({app, case_one}) => {
        return {lang: app.lang, value: case_one[columnName]};
    });

    const options = [{value: DEFAULT_SELECT_VALUE, label: get_msg_txt(lang, msg.select_default_label)}].concat(ageOptions);
    const error = value === DEFAULT_SELECT_VALUE ? get_msg_txt(lang, msg.age_error) : "";

    return <SelectRow
        id="age"
        isRequired
        columnName={columnName}
        label={get_msg_txt(lang, msg.age)}
        options={options}
        error={!dirty? "" :error}
        value={value}
        onDirty={()=>onDirty(true)}
    />;
});

export default AgeField;

AgeField.propTypes = {
};
