import Home_Route, {route_path as home_path} from "./Home_Route";
import ThankYou_Route, {route_path as thankyou_path} from "./ThankYou_Route";
import Announcement_Route, {route_path as announcement_path} from "./Announcement_Route";

export default [
    {
        exact: true,
        path: home_path,
        component: Home_Route,
        has_blue_footer: false,
        has_copyright_footer: true
    },
    {
        exact: true,
        path: thankyou_path,
        component: ThankYou_Route,
        has_blue_footer: true,
        has_copyright_footer: false
    },
    {
        exact: true,
        path: announcement_path,
        component: Announcement_Route,
        has_blue_footer: false,
        has_copyright_footer: true
    }
]
