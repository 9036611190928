import React from "react";
import styled from "styled-components";
import NoticeWhiteBoard from "../../../../layouts/NoticeLayout/mobile/NoticeWhiteBoard";
import NoticeHeader from "../../../../layouts/NoticeLayout/mobile/NoticeWhiteBoard/NoticeHeader";
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";

const Container = styled.div`
  width: 18.875rem;
  margin-top: 2rem;
`;

const MobileFirstNotice = (()=>{
    const lang = useLang();
    const rows = get_msg_txt(lang, msg.notice_contents1);

    return<Container>
        <NoticeHeader />
        <NoticeWhiteBoard rows={rows} buttonText={get_msg_txt(lang, msg.next_step)} />
    </Container>
});

export default MobileFirstNotice;

MobileFirstNotice.propTypes = {
};
