import {DEFAULT_CITY_VALUE} from "./CitySelect";
import {DEFAULT_AREA_VALUE} from "./AreaSelect";
import {DEFAULT_ROAD_VALUE} from "./RoadSelect";
import useCaseOne from "../../../../../../hooks/useCaseOne";

const useIsShowAddressDetails = () => {
    const {animal_location_city, animal_location_area, animal_location_road} = useCaseOne();

    const isCitySelected = animal_location_city !== DEFAULT_CITY_VALUE;
    const isAreaSelected = animal_location_area !== DEFAULT_AREA_VALUE;
    const isRoadSelected = animal_location_road !== DEFAULT_ROAD_VALUE;

    return isCitySelected && isAreaSelected && isRoadSelected;
};

export default useIsShowAddressDetails;