import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {useSelector} from "react-redux";
import {MdDateRange} from "react-icons/md";
import {withStyles} from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import twLocale from "date-fns/locale/zh-TW";
import enLocale from "date-fns/locale/en-US";
import {DatePicker, MuiPickersUtilsProvider,} from '@material-ui/pickers';
import {ZH_TW} from "../../../consts/lang";
import msg, {get_msg_txt} from "../../../consts/msg";

const Container = styled.div`
  position: relative;
  cursor: pointer;
  width: 100%;
`;

const DateIcon = styled(MdDateRange)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  cursor: pointer;
`

const styles = () => ({
    root: {
        width: "100%",
    }
});

function DateField({value, onChange, ...others}) {
    const lang = useSelector(state => state.app.lang);
    const locale = lang === ZH_TW ? twLocale : enLocale;

    return <Container>
        <DateIcon />
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
            <DatePicker
                {...others}
                format="yyyy-MM-dd"
                value={value}
                onChange={onChange}
                showTodayButton={true}
                okLabel={get_msg_txt(lang, msg.date_ok_msg)}
                cancelLabel={get_msg_txt(lang, msg.date_cancel_msg)}
                todayLabel={get_msg_txt(lang, msg.date_today_msg)}
            />
        </MuiPickersUtilsProvider>
    </Container>
}

DateField.propTypes = {
    label: PropTypes.string,
    value: PropTypes.object,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func
};

export default withStyles(styles)(DateField);
