import _ from "lodash";
import PropTypes from "prop-types";
import React, {useCallback} from "react";
import styled from "styled-components";
import {useDropzone} from 'react-dropzone';
import {useDispatch} from "react-redux";
import axios from "axios";
import DottedBox from "./DottedBox";
import UploadIcon from "./UploadIcon";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import useLang from "../../../../../hooks/useLang";
import {
    ON_CASE_FILE_FAILED,
    ON_CASE_FILE_TOO_BIG,
    ON_CASE_FILE_UPLOAD_PROGRESS,
    ON_CASE_FILE_UPLOADED,
    ON_CASE_FILES_UPLOADING
} from "../../../../../actions/types";
import {MAX_NUMBER_OF_UPLOAD_FILES, UPLOAD_URL} from "../../../../../config";
import utils from "../../../../../utils/utils";
import useIsWeb from "../../../../../hooks/useIsWeb";
import useFilterAcceptFiles, {imageExtensions, videoExtensions} from "../../../../../hooks/useFilterAcceptFiles";
import useShallowSelector from "../../../../../hooks/useShallowSelector";
import {UPLOADED} from "../../../../../consts/upload_status";

const InnerBox = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const UploadText = styled.div`
  color: #434242;
  font-size: 0.875rem;
  margin-top: 14px;
`;

export const isImageExt = (ext) => {
    return imageExtensions.has(ext);
};

export const isVideoExt = (ext) => {
    return videoExtensions.has(ext);
};

const useUploadedFiles = ()=>{
    return useShallowSelector(({case_one})=>{
        const {case_files=[]} = case_one;
        return case_files.filter(e=>e.status === UPLOADED)
    });
}

const useOnDrop = (dispatch)=>{
    const lang = useLang();
    const uploadedFiles = useUploadedFiles();
    const acceptFiles = useFilterAcceptFiles();

    return useCallback(async files => {
        files = await acceptFiles(files);
        if(uploadedFiles.length + files.length > MAX_NUMBER_OF_UPLOAD_FILES ){
            window.alert(get_msg_txt(lang, msg.reach_max_number_of_file));
            return;
        }


        const fileWithIds = files.map(file=>{
            return {file, id: _.uniqueId()};
        });
        dispatch({
            type: ON_CASE_FILES_UPLOADING,
            payload: fileWithIds
        });

        fileWithIds.forEach(({id, file}) => {
            //if this file size is over 30 MB
            if(utils.getFileSizeInMB(file) > 30){
                dispatch({
                    type: ON_CASE_FILE_TOO_BIG,
                    payload: { id, file }
                });
            }
            else{
                const body = new FormData();
                body.append('file', file);
                axios.post(UPLOAD_URL, body, {
                    onUploadProgress: event => {
                        const percentCompleted = Math.round((event.loaded * 100) / event.total);
                        dispatch({
                            type: ON_CASE_FILE_UPLOAD_PROGRESS,
                            payload: { id, percentCompleted }
                        });
                    }
                }).then(({data}) => {
                    dispatch({
                        type: ON_CASE_FILE_UPLOADED,
                        payload: { id, src: data.src, file }
                    });
                })
                .catch(() => {
                    dispatch({
                        type: ON_CASE_FILE_FAILED,
                        payload: { id }
                    });
                });
            }
        });

    }, [dispatch, lang, uploadedFiles.length, acceptFiles]);
};

const UploadButton = (({className}) => {
    const isWeb = useIsWeb();
    const dispatch = useDispatch();
    const lang = useLang();
    const onDrop = useOnDrop(dispatch);
    const {getRootProps, getInputProps} = useDropzone({onDrop, multiple: true});

    return <DottedBox {...getRootProps()} isWeb={isWeb} className={className}>
        <input {...getInputProps()} />
        <InnerBox>
            <UploadIcon isWeb={isWeb} />
            <UploadText>{get_msg_txt(lang, isWeb ? msg.web_upload_btn_label: msg.upload_btn_label)}</UploadText>
        </InnerBox>
    </DottedBox>
});

export default UploadButton;

UploadButton.propTypes = {
    className: PropTypes.string
};
