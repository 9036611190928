import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import GlobalSelect from "../../../../components/GlobalSelect";
import useLang from "../../../../hooks/useLang";
import msg, {get_msg_txt} from "../../../../consts/msg";

const Container = styled.header`
  font-size: 1.5rem;
  color: #000000;
  font-family: Roboto, serif;
  
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Title = styled.span`
  display: flex;
  justify-content: center;
  font-weight: bold;
  flex: 1;
`;

const NoticeHeader = (()=>{
    const lang = useLang();

    return <Container>
        <span />
        <Title>
            {get_msg_txt(lang, msg.notice)}
        </Title>
        <GlobalSelect />
    </Container>
});

export default NoticeHeader;

NoticeHeader.propTypes = {
    onNextStep: PropTypes.func
};
