import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";

const Container = styled.div`
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  overflow: hidden;
  min-height: 0;
  height: ${props=> props.open? props.height: 0};
`;

const AccordionContent = (({open, height, children: Children}) => {
    return <Container open={open} height={height}>
        {Children}
    </Container>
});

export default AccordionContent;

AccordionContent.propTypes = {
    open: PropTypes.bool,
    height: PropTypes.string,
    children: PropTypes.any
};
