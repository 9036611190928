import React from 'react';
import {useDispatch} from "react-redux";
import styled, {css} from "styled-components";
import {ON_CASE_FIELD_CHANGE} from "../../../../actions/types";
import _TextField from "../TextField";
import ColumnRow from "../ColumnRow";
import useIsWeb from "../../../../hooks/useIsWeb";

const TextField = styled(_TextField)`
  width: 11.875rem;
  
  ${props => props.isWeb && css`
    width: 100%;
  `};
`

const TextRow = (({id, columnName, isRequired=false, placeholder, label, value, error, onDirty=()=>{}}) => {
    const isWeb = useIsWeb();
    const dispatch = useDispatch();

    return <ColumnRow label={label} error={error} isRequired={isRequired}>
        <TextField
            id={id}
            isWeb={isWeb}
            type="text"
            placeholder={placeholder}
            value={value}
            onChange={({target: {value}})=>{
                onDirty();
                dispatch({
                    type: ON_CASE_FIELD_CHANGE,
                    columnName,
                    payload: value
                });
            }}
            onKeyUp={onDirty}
            onBlur={onDirty}
        />
    </ColumnRow>
});

export default TextRow;

TextRow.propTypes = {
};
