import React, {memo} from 'react';
import PropTypes from "prop-types";
import {EMAIL} from "../../config";
import LinkBase from "../LinkBase";

const EmailText = memo(({test_id=""}) => {
    return <LinkBase test_id={test_id} title={EMAIL} href={`mailto:${EMAIL}`}>{EMAIL}</LinkBase>
});

EmailText.propTypes = {
    number: PropTypes.string,
    test_id: PropTypes.string
};

export default EmailText;
