import React from 'react';
import {Icon} from "@material-ui/core";
import PropTypes from "prop-types";

const ImageButton = (()=>{
    return <Icon
        className='fas fa-image'
        style={{ fontSize: 50 }}
    />
});

export default ImageButton;

ImageButton.propTypes = {
    size: PropTypes.number,
    onClick: PropTypes.string,
    lang: PropTypes.string,
    test_id: PropTypes.string,
    link: PropTypes.string
};
