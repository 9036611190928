import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Header from "./Header";
import useScrollToTop from "../../../hooks/useScrollToTop";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-size: 16px;
`;

const Content = styled.div`
  width: 100%;
`;

const MobileStepLayout = (({children: Children})=>{
    useScrollToTop();
    return <>
        <Container>
            <Header />
            <Content>
                {Children}
            </Content>
        </Container>
    </>
});

export default MobileStepLayout;

MobileStepLayout.propTypes = {
    children: PropTypes.any,
};
