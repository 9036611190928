import React from "react";
import styled from "styled-components";
import LinkBase from "../../../../components/LinkBase";
import {SPCA_OFFICIAL_SITE} from "../../../../config";
import {AiOutlineArrowRight as MaterialArrowRightIcon} from "react-icons/ai";

const Container = styled(LinkBase)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  display: flex;
  align-items: center;
  
  color: #2364FA;
  font-weight: bold;
`;

const ArrowRightIcon = styled(MaterialArrowRightIcon)`
  margin-left: 0.625rem;
`

const WebSiteLink = ()=>{
    return <Container target="_blank" href={SPCA_OFFICIAL_SITE}>
        <div>前往官網</div>
        <ArrowRightIcon />
    </Container>
}

export default WebSiteLink;