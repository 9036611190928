import React from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import RadioButtons from "../RadioButtons";
import {ON_CASE_FIELD_CHANGE} from "../../../../../actions/types";

const Container = styled(RadioButtons)`
  display: flex;
  justify-content: space-between;
`;

const IsPersonalInfo = (() => {
    const dispatch = useDispatch();
    const checked = useSelector(state=>state.case_one.is_personal_info);

    return <Container checked={checked} onChange={value=>{
        dispatch({
            type: ON_CASE_FIELD_CHANGE,
            columnName: "is_personal_info",
            payload: value
        });
    }}>

    </Container>
});

export default IsPersonalInfo;

IsPersonalInfo.propTypes = {
};
