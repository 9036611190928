import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import msg, {get_msg_txt} from '../../../consts/msg';
import useLang from "../../../hooks/useLang";
import useContent from "../shared/useContent";
import ContactUs from "./ContactUs";

const Container = styled.div`
  width: 40.625rem;
  margin-bottom: 10rem;
`;

const Title = styled.div`
  background-color: #95BC7D;
  color: #FFFFFF;
  font-size: 1.5rem;
  border-radius: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 49px;
  font-weight: bold;
`;

const Content = styled.div`
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
`;

const WebThankYou = (()=>{
    const lang = useLang();
    const content = useContent();

    return <Container>
        <Title>
            {get_msg_txt(lang, msg.thankyou_success) }
        </Title>
        <Content>
            {content}
        </Content>
        <ContactUs />
    </Container>
});

WebThankYou.propTypes = {
    case_num: PropTypes.string,
    animal_location: PropTypes.string
};

export default WebThankYou;
