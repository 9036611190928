import styled from "styled-components";

const Arrow = styled.div`
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #707070;
`;

export default Arrow;

Arrow.propTypes = {
};
