import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Loading from "../../../components/Loading";
import bg from '../../shared/bg.svg';
import useIsLoaded from "../shared/useIsLoaded";
import useScrollToTop from "../../../hooks/useScrollToTop";

const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  min-height: 100vh;
  background-image: url("${bg}");
  
  font-size: 16px;
`;

const MobileNoticeLayout = (({children})=>{
    useScrollToTop();
    const is_loaded = useIsLoaded();

    if (!is_loaded) {
        return <Loading/>
    }
    else {
        return <>
            <Container>
                {children}
            </Container>
        </>
    }
});

export default MobileNoticeLayout;

MobileNoticeLayout.propTypes = {
    children: PropTypes.any,
};
