import React from 'react';
import styled, {css} from "styled-components";
import useIsWeb from "../../../hooks/useIsWeb";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  margin-top: 1.25rem;
  
  ${props => props.isWeb && css`
    justify-content: flex-start;
    margin-bottom: 1.25rem;
  `};
`;

const Inner = styled.div`
  width: 18.75rem;
  font-size: 1.125rem;
  
  ${props=> props.isWeb && css`
    width: 100%;
  `};
`;

const WholeRow = (({className, children: Children}) => {
    const isWeb = useIsWeb();

    return <Container isWeb={isWeb}>
        <Inner className={className} isWeb={isWeb}>
            {Children}
        </Inner>
    </Container>
});

export default WholeRow;

WholeRow.propTypes = {
};
