import React from 'react';
import Select from "../../../shared/SelectRow/Select";
import {DEFAULT_SELECT_VALUE} from "../../../../../config";
import msg, {get_msg_txt} from "../../../../../consts/msg";
import useLang from "../../../../../hooks/useLang";
import useOnChange from "../AnimalDetails/shared/useOnChange";
import useAnimalSpeciesList from "../../../../../hooks/useAnimalSpeciesList";

const AnimalSpeciesSelect = (({id, value}) => {
    const lang = useLang();
    const onChange = useOnChange();
    const {isLoading, data} = useAnimalSpeciesList();

    if(isLoading) return <div>Loading...</div>;

    const animal_species = data.data;
    const options = [{value: DEFAULT_SELECT_VALUE, label: get_msg_txt(lang, msg.select_default_label)}].concat(animal_species.map(({id, name}) => {
        return {value: id, label: name};
    }));

    return <Select
        options={options}
        value={value}
        onChange={value => {
            onChange({id, value, columnName: "animal_species"});
        }}
    />
});

export default AnimalSpeciesSelect;

AnimalSpeciesSelect.propTypes = {
};
