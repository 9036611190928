import styled from "styled-components";

const Circle = styled.div`
  width: 1.25rem;
  height: 1.25rem;
  background-size: cover;
  background-repeat:no-repeat;
  background-blend-mode:multiply;
  background-image: url("${props => props.src}");
`;

export default Circle;

Circle.propTypes = {
};
