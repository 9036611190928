import {EMPTY_TYPE, ON_CASE_SUBMIT} from "./types";
import {DEFAULT_LANG} from '../config';
import msg, {get_msg_txt} from '../consts/msg';
import {post} from './tools';
import {redirect_route} from '../routes/ThankYou_Route';
import utils from "../utils/utils";
import {UPLOADED} from '../consts/upload_status';

const url_prefix = "/case";

function get_body({case_one = {}}){
    const {city="", area="", road="", address="", case_files=[]} = case_one;
    const body = {...case_one };
    body.animal_location = `${city}${area}${road}${address}`;
    body.case_files = case_files.filter(e=>e.status===UPLOADED).map(e=>e.src);
    body.time_of_sighting = utils.get_taipei_date(body.time_of_sighting);
    return body;
}

export function onSubmit({lang=DEFAULT_LANG, case_one={}}){
    if(window.confirm(get_msg_txt(lang, msg.submit_confirm_msg))){
        const body = get_body({case_one});
        const callback = ({data})=>redirect_route(data);
        return post(
        {
            url: url_prefix,
            name: "case_submit",
            type: ON_CASE_SUBMIT,
            body,
            callback
        });
    }
    else{
        return { type: EMPTY_TYPE }
    }
}
