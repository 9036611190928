import React from "react";
import styled from 'styled-components';
import WebNoticeLayout from "../../NoticeLayout/web";
import Top from "./Top";

const Container = styled.div`
    width: 100%;
    display: grid;
`;

const Bottom = styled.div`
  min-height: 25rem;
  display: flex;
  justify-content: center;
`;

const WebStepLayout = ({children}) => {
    return <WebNoticeLayout>
        <Container>
            <Top />
            <Bottom>{children}</Bottom>
        </Container>
    </WebNoticeLayout>
};

export default WebStepLayout;
