import _ from 'lodash';
import React from 'react';
import styled, {css} from "styled-components";
import ContentRow from "../ContentRow";
import _GreenCircle from "../../../../GreenCircle";
import ImageRow from "../ImageRow";
import useIsWeb from "../../../../../hooks/useIsWeb";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${props => props.isWeb ? "start": "center"};
  background-color: ${props=>props.isWhite? "":"#F2EFEF"};
`;

const GreenCircle = styled(_GreenCircle)`
  width: 20px;
  height: 20px;
  
  ${props => !props.isWeb && css`
    margin-right: 1rem;
  `};
`
const Block = styled.div`
  margin-top: 1.625rem;
  margin-bottom: 1.625rem;
  width: 18.75rem;
  display: grid;
  grid-template-columns: 2.25rem 1fr;
  grid-template-rows: 1.75rem 1fr;
  grid-template-areas:
    "circle title"
    ".    content";
    
  ${props => props.isWeb && css`
    width: 100%;
    grid-template-columns: 4.375rem 1fr;
    grid-template-rows: 3.125rem 1fr;
  `}
`;

const CircleArea = styled.div`
  grid-area: circle;
  
  display: flex;
  justify-content: center;
  align-items: center;
  
  ${props => props.isWeb && css`
    background-color: #F2EFEF;
  `};
`;

const TitleArea = styled.div`
  grid-area: title;
  
  display: flex;
  align-items: center;
  ${props => props.isWeb && css`
    background-color: #F2EFEF;
  `};
`;

const ContentArea = styled.div`
  grid-area: content;
  padding-top: 1.25rem;
  
  >:not(:last-child) {
      margin-bottom: 0.8125rem;
  }
`;

const GreyLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #9F9797;
`

const Text = styled.div`
  font-size: 1rem;
`;

const DataBlock = ({isWhite, title, rows=[]}) => {
    const isWeb = useIsWeb();

    return <Container isWhite={isWhite} isWeb={isWeb}>
        <Block isWeb={isWeb}>
            <CircleArea isWeb={isWeb}>
                <GreenCircle isWeb={isWeb} />
            </CircleArea>
            <TitleArea isWeb={isWeb}>{title}</TitleArea>
            <ContentArea>
                {rows.map(({isLine, isImg, url, name, isText, text, left, right}) => {
                    const key = _.uniqueId();
                    if(isLine) return <GreyLine key={key} />
                    else if(isImg) return <ImageRow key={key} alt={name} url={url} name={name}/>
                    else if(isText) return <Text key={key}>{text}</Text>
                    return <ContentRow key={key} left={left} right={right}/>
                })}
            </ContentArea>
        </Block>
    </Container>;
};

export default DataBlock;

DataBlock.propTypes = {
};
