import {getFileExt} from "./useGetFileExt";

export const imageExtensions = new Set(["ase", "art", "bmp", "blp", "cd5", "cit", "cpt", "cr2", "cut", "dds", "dib", "djvu", "egt", "exif", "gif", "gpl", "grf", "icns", "ico", "iff", "jng", "jpeg", "jpg", "jfif", "jp2", "jps", "lbm", "max", "miff", "mng", "msp", "nitf", "ota", "pbm", "pc1", "pc2", "pc3", "pcf", "pcx", "pdn", "pgm", "PI1", "PI2", "PI3", "pict", "pct", "pnm", "pns", "ppm", "psb", "psd", "pdd", "psp", "px", "pxm", "pxr", "qfx", "raw", "rle", "sct", "sgi", "rgb", "int", "bw", "tga", "tiff", "tif", "vtf", "xbm", "xcf", "xpm", "3dv", "amf", "ai", "awg", "cgm", "cdr", "cmx", "dxf", "e2d", "egt", "eps", "fs", "gbr", "odg", "svg", "stl", "vrml", "x3d", "sxd", "v2d", "vnd", "wmf", "emf", "art", "xar", "png", "webp", "jxr", "hdp", "wdp", "cur", "ecw", "iff", "lbm", "liff", "nrrd", "pam", "pcx", "pgf", "sgi", "rgb", "rgba", "bw", "int", "inta", "sid", "ras", "sun", "tga"]);
export const videoExtensions = new Set(["3g2", "3gp", "aaf", "asf", "avchd", "avi", "drc", "flv", "m2v", "m4p", "m4v", "mkv", "mng", "mov", "mp2", "mp4", "mpe", "mpeg", "mpg", "mpv", "mxf", "nsv", "ogg", "ogv", "qt", "rm", "rmvb", "roq", "svi", "vob", "webm", "wmv", "yuv"]);

const checkFile = async (file) => {
    return new Promise(resolve => {
        return getFileExt(file).then(ext=>{
            resolve(imageExtensions.has(ext) || videoExtensions.has(ext));
        }).catch(()=>{
            resolve(false);
        });
    });
}

function mapAsync(array, checkFile) {
    return Promise.all(array.map(checkFile));
}

async function filterAsync(array, checkFile) {
    const filterMap = await mapAsync(array, checkFile);
    return array.filter((value, index) => filterMap[index]);
}

const useFilterAcceptFiles = () => {
    return async files=>{
        return await filterAsync(files, checkFile);
    }
};

export default useFilterAcceptFiles;