import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.li`
  color: #000000;
  font-size: 1.25rem;
  display: grid;
  grid-template-columns: 1.125rem 14.75rem;
  margin: 0;
  padding: 0;
`;

const Square = styled.div`
  width: 10px;
  height: 10px;
  background-color: #D93870;
  margin-top: 0.3125rem;
`;

const Text = styled.span`
  margin: 0;
`;

const NoticeRow = (({text: __html, className})=>{
    return  <Container className={className}>
        <Square />
        <Text dangerouslySetInnerHTML={{__html}} />
    </Container>
});

export default NoticeRow;

NoticeRow.propTypes = {
    text: PropTypes.any
};
