export const RECEIVE_ERROR_MSG = "RECEIVE_ERROR_MSG";
export const APP_INIT = "APP_INIT";
export const APP_INIT_ERROR = "APP_INIT_ERROR";
export const APP_CHANGE_LANG = "APP_CHANGE_LANG";
export const ON_NEXT_STEP = "ON_NEXT_STEP";
export const ON_STEP_CHANGE = "ON_STEP_CHANGE";
export const ON_PREV_STEP = "ON_PREV_STEP";
export const ON_CASE_FIELD_CHANGE = "ON_CASE_FIELD_CHANGE";
export const RECEIVE_FETCHING = "RECEIVE_FETCHING";
export const ON_ADDRESS_CITY_CHANGE = "ON_ADDRESS_CITY_CHANGE";
export const ON_ADDRESS_AREA_CHANGE = "ON_ADDRESS_AREA_CHANGE";
export const ON_ADDRESS_ROAD_CHANGE = "ON_ADDRESS_ROAD_CHANGE";
export const ON_ADDRESS_DETAILS_CHANGE = "ON_ADDRESS_DETAILS_CHANGE";
export const ON_ANIMAL_DETAILS_CHANGE = "ON_ANIMAL_DETAILS_CHANGE";
export const ON_ANIMAL_DETAILS_ADD = "ON_ANIMAL_DETAILS_ADD";
export const ON_ANIMAL_DETAILS_REMOVE = "ON_ANIMAL_DETAILS_REMOVE";
export const ON_CASE_FILES_UPLOADING = "ON_CASE_FILES_UPLOADING";
export const ON_CASE_FILE_UPLOADED = "ON_CASE_FILE_UPLOADED";
export const ON_CASE_FILE_UPLOAD_PROGRESS = "ON_CASE_FILE_UPLOAD_PROGRESS";
export const ON_CASE_FILE_TOO_BIG = "ON_CASE_FILE_TOO_BIG";
export const ON_CASE_FILE_FAILED = "ON_CASE_FILE_FAILED";
export const ON_CASE_FILE_DELETED = "ON_CASE_FILE_DELETED";
export const EMPTY_TYPE = "EMPTY_TYPE";
export const ON_CASE_SUBMIT = "ON_CASE_SUBMIT";
export const FETCH_ANIMAL_SPECIES = "FETCH_ANIMAL_SPECIES";
