import React from "react";
import styled from "styled-components";

const Container = styled.button`
  width: 13.75rem;
  height: 2.5rem;
  margin-top: 1rem;
  font-size: 1rem;
  border-radius: 7px;
  background-color: ${props=>props.disabled? "#A2B2D9" : "#6C87C6"} ;
  color: white;
  border: none;
  outline: none;
  
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
  cursor: ${props=>props.disabled? "not-allowed" : "pointer"};
  
  &:hover{
    box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  }
  
  &:last-child {
    margin-bottom: 1rem;
  }
`;

const Button = (({className, disabled=false, children="", onClick=()=>{}})=>{
    return <Container className={className} type="button" onClick={onClick} disabled={disabled}>{children}</Container>
});

export default Button;

Button.propTypes = {
};
