import React from "react";
import styled from "styled-components";
import svg from './animals.svg';
import WebSiteLink from "./WebSiteLink";

const Container = styled.div`
  width: 100%;
  height: 200px;
  position: relative;
  display: grid;
  grid-template-rows: auto 60px;
`;

const Animals = styled.div`
  z-index: 999;
  position: absolute;
  top: 0;
`;

const White = styled.div`
  background-color: white;
  width: 100%;
`;

const Blue = styled.div`
  background-color: #284077;
  width: 100%;
  border-bottom-left-radius: 25px;
  border-bottom-right-radius: 25px;
`;

const WebFooter = ({className}) => {
    return <Container className={className}>
        <Animals>
            <img alt="animals" src={svg} />
        </Animals>
        <White />
        <Blue />
        <WebSiteLink />
    </Container>
};

export default WebFooter;