import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import msg, {get_msg_txt} from '../../../consts/msg';
import _Bottom from "../../Home/SecondNotice/mobile/Bottom";
import useLang from "../../../hooks/useLang";
import useContent from "../shared/useContent";

const WhiteBoard = styled.div`
  width: 18.875rem;
  background-color: #FFFDFD;
  border-radius: 7px;
  padding: 1.25rem;
`;

const Title = styled.div`
  color: #434242;
  font-weight: bold;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
`;

const Bottom = styled(_Bottom)`
  margin-top: 1.25rem;
`

const MobileThankYou = (()=>{
    const lang = useLang();
    const content = useContent();

    return <>
        <WhiteBoard>
            <Title>
                {get_msg_txt(lang, msg.thankyou_success) }
            </Title>
            <div>
                {content}
            </div>
        </WhiteBoard>
        <Bottom />
    </>
});

MobileThankYou.propTypes = {
    case_num: PropTypes.string,
    animal_location: PropTypes.string
};

export default MobileThankYou;
