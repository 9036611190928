import React from "react";
import styled from "styled-components";
import WholeRow from "../../../../WholeRow";
import CitySelect from "../shared/CitySelect";
import AreaSelect from "../shared/AreaSelect";
import RoadSelect from "../shared/RoadSelect";
import msg, {get_msg_txt} from "../../../../../../consts/msg";
import AddressDetailsField from "../shared/AddressDetailsField";
import RedStar from "../../../../RedStar";
import useLang from "../../../../../../hooks/useLang";
import useIsShowAddressDetails from "../shared/useIsShowAddressDetails";
import RedText from "../shared/RedText";

const Container = styled.div`
  margin-bottom: 1.25rem;
`;

const MobilePlaceRow = () => {
    const lang = useLang();
    const isShowAddressDetails = useIsShowAddressDetails();

    return <Container>
        <WholeRow>
            <div>
                {get_msg_txt(lang, msg.animal_address_title)}<RedStar />
            </div>
            <RedText>
                {get_msg_txt(lang, msg.hint_for_animal_address)}
            </RedText>
        </WholeRow>
        <CitySelect />
        <AreaSelect />
        <RoadSelect />
        {isShowAddressDetails && <WholeRow>
            <AddressDetailsField />
        </WholeRow>}
    </Container>
};

export default MobilePlaceRow;
