import React from "react";
import styled from "styled-components";
import Row from "../../../../../WholeRow";
import cities from "./cities";
import msg, {get_msg_txt} from "../../../../../../../consts/msg";
import _Select from "../../../../../shared/SelectRow/SelectCaseOneColumn";
import useLang from "../../../../../../../hooks/useLang";

const Select = styled(_Select)`
  select{
    width: 100%;
  }
`;

export const DEFAULT_CITY_VALUE = "DEFAULT_CITY_VALUE";

const CitySelect = () => {
    const lang = useLang();

    const options = [{label: get_msg_txt(lang, msg.animal_location_city_list_choose), value: DEFAULT_CITY_VALUE}].concat(cities.map((value) => {
        return {value, label: value};
    }));

    return <Row>
        <Select
            columnName="animal_location_city"
            options={options}
        />
    </Row>
};

export default CitySelect;
